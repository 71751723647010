import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} reciever uuid of person to send to
 * @param {*} message   message body
 * @returns status error || success and corresponding data
 */
const sendMessage = async (reciever, message) => {
	const url = `${BASE_URL}/api/messages/send`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			reciever: reciever,
			message: message,
		}),
	});

	if (res.ok) {
		let responseData = await res.json();
		return { status: 'success', responseData: responseData };
	} else {
		let responseData = await res.json();
		return { status: 'error', responseData: responseData };
	}
};
export default sendMessage;
