import imageCompression from 'browser-image-compression';

async function compressImage(file) {
	const options = {
		maxSizeMB: 3,
		maxWidthOrHeight: 1920, // You can adjust this value based on your requirements
		useWebWorker: true,
	};

	try {
		const compressedFile = await imageCompression(file, options);

		return compressedFile;
	} catch (error) {
		console.error('Error compressing image:', error);
		throw error;
	}
}
export default compressImage;
