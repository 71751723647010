import { useEffect, useState } from 'react';
import AccountPage from './AccountPage';
import { Footer } from './../components/ui/Footer';
import Home from './Home.js';
import { IonPage } from '@ionic/react';
import MatchesPage from './MatchesPage.js';
import { PushNotifications } from '@capacitor/push-notifications';
import { Toast } from '@capacitor/toast';
import getLikedBy from '../data/get/getLikedBy';
import getMatches from '../data/get/getMatches';
import getProfile from '../data/profile/get';
import getProfileImages from '../data/profile/getProfileImages';
import getSaved from '../data/get/getSaved';
import updateFCM from '../data/notifications/updateFCM';
import { registerPlugin } from '@capacitor/core';
import '../input.css';
import SavesWavesPage from './SavesWavesPage';
import updateUserLocation from '../data/addLocation';
import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation';
import getUsers from '../data/get/getUsers';
import sendMessage from '../data/messages/send';
import isInRange from '../data/location/isInRange';
import updateInRange from '../data/location/updateInRange';

const SUBSCRIPTIONS = [];

const subscribe = (subscription) => {
	SUBSCRIPTIONS.push(subscription);
};

/// Helper method to unsubscribe from all registered BackgroundGeolocation event-listeners.
const unsubscribe = () => {
	SUBSCRIPTIONS.forEach((subscription) => subscription.remove());
	SUBSCRIPTIONS.splice(0, SUBSCRIPTIONS.length);
};
const MainPage = () => {
	const [currPage, setCurrPage] = useState(1);
	const [userProf, setUserProf] = useState(null);
	const [profileImages, setProfileImages] = useState([]);
	const [convoData, setConvoData] = useState(null);
	const [matches, setMatches] = useState(null);
	const [loadedUsers, setLoadedUsers] = useState(false);
	const [didPerform, setDidPerform] = useState(false);
	const [usersToSwipe, setUsersToSwipe] = useState([]);
	const [showWarning, setShowWarning] = useState(false);
	const [showFooter, setShowFooter] = useState(true);
	const [savedUsers, setSavedUsers] = useState(null);
	const [likedBy, setLikedBy] = useState(null);
	const [ready, setReady] = useState(false);
	const [events, setEvents] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [userLon, setUserLon] = useState('');
	const [userLat, setUserLat] = useState('');

	useEffect(() => {
		getLikedBy().then((data) => {
			setLikedBy(data.data);
		});

		getSaved().then((data) => {
			if (data?.data?.status === 'succ') {
				setSavedUsers(data?.data?.data);
			}
		});
	}, []);

	const addEvent = (name, params) => {
		let timestamp = new Date();
		const event = {
			expanded: false,
			timestamp:
				timestamp.toLocaleDateString() +
				' ' +
				timestamp.toLocaleTimeString(),
			name: name,
			params: JSON.stringify(params, null, 2),
		};
		setEvents((previous) => [event]);
	};
	useEffect(() => {
		if (ready) {
			getInitLoc();
		}
	}, [ready]);
	useEffect(() => {
		subscribe(
			BackgroundGeolocation.onLocation(
				async (location) => {
					await updateUserLocation(
						location?.coords?.latitude,
						location?.coords?.longitude
					);
					setUserLat(location?.coords?.latitude);
					setUserLon(location?.coords?.longitude);

					addEvent('onLocation', location);
				},
				(error) => {
					console.warn('[onLocation] ERROR: ', error);
				}
			)
		);

		/// 2. ready the plugin.
		BackgroundGeolocation.ready({
			// Geolocation Config
			desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
			distanceFilter: 50,
			// Activity Recognition
			stopTimeout: 3,
			reset: true,
			// Application config
			debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
			logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
			stopOnTerminate: false, // <-- Allow the background-service to continue tracking when user closes the app.
			startOnBoot: true, // <-- Auto start tracking when device is powered-up.
		}).then((state) => {
			setReady(true);
			console.log(state);
			setEnabled(state.enabled);
			addEvent('State', state);
			BackgroundGeolocation.start(function () {
				console.log('started');
			});
		});

		return () => {
			// Remove BackgroundGeolocation event-subscribers when the View is removed or refreshed
			// during development live-reload.  Without this, event-listeners will accumulate with
			// each refresh during live-reload.
		};
	}, []);
	const getInitLoc = async () => {
		let location = await BackgroundGeolocation.getCurrentPosition({
			timeout: 30, // 30 second timeout to fetch location
			persist: true, // Defaults to state.enabled
			maximumAge: 5000, // Accept the last-known-location if not older than 5000 ms.
			desiredAccuracy: 10, // Try to fetch a location with an accuracy of `10` meters.
			samples: 3, // How many location samples to attempt.
			extras: {
				// Custom meta-data.
				route_id: 123,
			},
		});
		setUserLat(location?.coords?.latitude);
		setUserLon(location?.coords?.longitude);

		console.log('MY LOCAITON IS', location);
	};

	useEffect(() => {
		PushNotifications.checkPermissions().then((res) => {
			if (res.receive !== 'granted') {
				PushNotifications.requestPermissions().then((res) => {
					if (res.receive === 'denied') {
						console.log('Permissions denied.');
					} else {
						// showToast('Push Notification permission granted');
						register();
					}
				});
			} else {
				register();
			}
		});
	}, []);

	useEffect(() => {
		getProfile().then((data) => {
			setUserProf(data);
		});
		getProfileImages().then((data) => {
			if (data?.images[0] === undefined) {
				setProfileImages(null);
			} else {
				setProfileImages(data?.images);
			}
		});
		messageDataOnLoad();
	}, []);

	useEffect(() => {
		if (didPerform) {
			getProfile().then((data) => {
				setUserProf(data);
			});
			getProfileImages().then((data) => {
				if (data?.images[0] === undefined) {
					setProfileImages(null);
				} else {
					setProfileImages(data?.images);
				}
			});
			setDidPerform(false);
		}
	}, [didPerform]);

	const register = async () => {
		// Register with Apple / Google to receive push via APNS/FCM
		PushNotifications.register();

		// On success, we should be able to receive notifications
		PushNotifications.addListener('registration', async (token) => {
			// Work with FCM_TOKEN

			await updateFCM(token);
			// showToast('Push registration success');
		});

		// Some issue with our setup and push will not work
		PushNotifications.addListener('registrationError', (error) => {
			alert('Error on registration: ' + JSON.stringify(error));
		});

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener(
			'pushNotificationReceived',
			(notification) => {
				setNotifications((notifications) => [
					...notifications,
					{
						id: notification.id,
						title: notification.title,
						body: notification.body,
						type: 'foreground',
					},
				]);
			}
		);

		// Method called when tapping on a notification
		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			(notification) => {
				setNotifications((notifications) => [
					...notifications,
					{
						id: notification.notification.data.id,
						title: notification.notification.data.title,
						body: notification.notification.data.body,
						type: 'action',
					},
				]);
			}
		);
	};

	const showToast = async (msg) => {
		await Toast.show({
			text: msg,
		});
	};

	const messageDataOnLoad = () => {
		getMatches().then((data) => {
			console.log(data);
			if (data.status === 'error') {
				setMatches(null);
			} else {
				setMatches(data?.data?.userData);

				setConvoData(data?.data?.formattedConversationData);
			}
		});
	};

	return (
		<IonPage color="wave-bg" className="bg-wave-bg --ion-safe-area-top">
			{currPage === 1 ? (
				<Home
					userLon={userLon}
					userLat={userLat}
					setUserLat={setUserLat}
					setUserLon={setUserLon}
					setShowFooter={setShowFooter}
					setShowWarning={setShowWarning}
					showWarning={showWarning}
					userProf={userProf}
					setUsersToSwipe={setUsersToSwipe}
					usersToSwipe={usersToSwipe}
					loadedUsers={loadedUsers}
					setLoadedUsers={setLoadedUsers}
					likedBy={likedBy}
					setSavedUsers={setSavedUsers}
					setLikedBy={setLikedBy}
					matches={matches}
					setMatches={setMatches}
				/>
			) : null}
			{currPage === 0 ? (
				<AccountPage
					userProf={userProf}
					profileImages={profileImages}
					setProfileImages={setProfileImages}
					setDidPerform={setDidPerform}
					setUserProf={setUserProf}
					showWarning={showWarning}
					setShowWarning={setShowWarning}
					setShowFooter={setShowFooter}
				/>
			) : null}

			{currPage === 2 ? (
				<MatchesPage
					setShowFooter={setShowFooter}
					convoData={convoData}
					setConvoData={setConvoData}
					setMatches={setMatches}
					matches={matches}
				/>
			) : null}

			{currPage === 3 ? (
				<SavesWavesPage
					setUserLat={setUserLat}
					userLat={userLat}
					setUserLon={setUserLon}
					userLon={userLon}
					setShowFooter={setShowFooter}
					setShowWarning={setShowWarning}
					showWarning={showWarning}
					userProf={userProf}
					setUsersToSwipe={setUsersToSwipe}
					usersToSwipe={usersToSwipe}
					loadedUsers={loadedUsers}
					setLoadedUsers={setLoadedUsers}
					likedBy={likedBy}
					savedUsers={savedUsers}
					setSavedUsers={setSavedUsers}
					setLikedBy={setLikedBy}
					matches={matches}
					setMatches={setMatches}
				/>
			) : null}

			{currPage !== -1 && showFooter ? (
				<Footer
					currPage={currPage}
					setCurrPage={setCurrPage}
					UserImage={
						profileImages !== null && profileImages[0]
							? profileImages[0]
							: null
					}
					User={userProf}
				/>
			) : null}
		</IonPage>
	);
};
export default MainPage;
