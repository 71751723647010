import '../css/AccountPage.css';
import '../input.css';
import 'swiper/css';
import 'swiper/css/pagination';

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { IoIosLogOut, IoMdSettings } from 'react-icons/io';
import {
	IonContent,
	IonRefresher,
	IonRefresherContent,
	IonToast,
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from 'react';

import { AiFillEdit } from 'react-icons/ai';
import BlockedUsersModal from '../components/BlockedUsersModal';
import { CalculateAge } from '../components/utils/FormatData';
import EditBioModal from '../components/EditBioModal';
import EditProfileModal from '../components/EditProfileModal';
import LoadingPage from '../components/ui/LoadingPage';
import { MdAddAPhoto } from 'react-icons/md';
import SettingsModal from '../components/SettingsModal';
import SwipePopUp from '../components/swipe/NewSwipe';
import { TbLockOff } from 'react-icons/tb';
import { chevronDownCircleOutline } from 'ionicons/icons';
import getProfile from '../data/profile/get';
import getProfileImages from '../data/profile/getProfileImages';
// import verifyUser from '../data/auth/verifyUser';

const AccountPage = ({
	userProf,
	profileImages,
	setProfileImages,
	setDidPerform,
	setUserProf,
	showWarning,
	setShowWarning,
	setShowFooter,
}) => {
	const [profPreview, setProfPreview] = useState(false);
	const [profile, setProfile] = useState(userProf);
	const [errorMessage, setErrorMessage] = useState(null);
	const [refreshProf, setRefreshProf] = useState(false);
	const [isEditProfileModalActive, setEditProfileModalStatus] =
		useState(false);
	const [isBlockedUsersModalActive, setBlockedUsersModalStatus] =
		useState(false);
	const [isSettingsModalActive, setSettingsModalStatus] = useState(false);
	const [isEditBioModalActive, setEditBioModalStatus] = useState(false);
	const [pullToRefresh, setPullToRefresh] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	useEffect(() => {
		setProfile(userProf);
	}, [userProf]);

	useEffect(() => {
		if (refreshProf) {
			getProfile().then((data) => {
				setUserProf(data);
			});
			getProfileImages().then((data) => {
				if (data?.images[0] === undefined) {
					setProfileImages(null);
				} else {
					setProfileImages(data?.images);
				}
			});
			setRefreshProf(false);
		}
	}, [refreshProf]);
	const pageRef = useRef();

	const doRefresh = async (event) => {
		setPullToRefresh(!pullToRefresh);
		getProfile().then((data) => {
			setUserProf(data);
		});
		if (userProf?.isVisible) {
			setShowWarning(true);
		} else {
			setShowWarning(false);
		}
		getProfileImages().then((data) => {
			if (data?.images[0] === undefined) {
				setProfileImages(null);
			} else {
				setProfileImages(data?.images);
			}
		});
		setTimeout(() => {
			event.detail.complete();
		}, 2000);
	};

	return (
		<>
			<SwipePopUp
				isOpen={profPreview}
				setEditProfileModalStatus={setEditProfileModalStatus}
				setShowFooter={setShowFooter}
				SetStatus={setProfPreview}
				setRefreshProf={setRefreshProf}
				User={profile}
				showLikeOptions={false}></SwipePopUp>

			<IonContent ref={pageRef} color="secondary" scrollY={false}>
				<meta http-equiv="Pragma" content="no-cache" />
				<meta http-equiv="Expires" content="0" />
				<IonRefresher
					slot="fixed"
					className="mt-safe z-50"
					onIonRefresh={doRefresh}>
					<IonRefresherContent
						color={'primary'}
						pullingIcon={chevronDownCircleOutline}
						className=""
						refreshingSpinner={'crescent'}></IonRefresherContent>
				</IonRefresher>
				<IonToast
					isOpen={errorMessage !== null}
					message={errorMessage}
					duration={1000}
					onWillDismiss={() => {
						setErrorMessage(null);
					}}
					className="bg-red-500"
					position="top"
				/>

				<EditProfileModal
					showBioEdit={true}
					profileImages={profileImages}
					setProfileImages={setProfileImages}
					setRefreshProf={setRefreshProf}
					isOpen={isEditProfileModalActive}
					PresentingElement={pageRef.current}
					SetStatus={setEditProfileModalStatus}
					User={profile}
				/>

				<BlockedUsersModal
					isOpen={isBlockedUsersModalActive}
					PresentingElement={pageRef?.current}
					SetStatus={setBlockedUsersModalStatus}
					User={profile}
				/>

				<EditBioModal
					isOpen={isEditBioModalActive}
					PresentingElement={pageRef.current}
					SetStatus={setEditBioModalStatus}
					Bio={profile?.bio}
					setUserProf={setUserProf}
				/>

				<SettingsModal
					isOpen={isSettingsModalActive}
					PresentingElement={pageRef.current}
					SetStatus={setSettingsModalStatus}
					userProf={userProf}
					profileImages={profileImages}
					setProfileImages={setProfileImages}
					setDidPerform={setDidPerform}
					setUserProf={setUserProf}
					showWarning={showWarning}
					setShowWarning={setShowWarning}
				/>

				{profPreview ? (
					<div className="h-screen w-full fixed bg-gray-500 bg-opacity-40 z-30"></div>
				) : null}

				<div className="flex items-center px-4 pt-safe justify-between">
					<IoIosLogOut
						onClick={() => {
							localStorage.clear();
							window.location = '/welcome';
						}}
						className="text-2xl text-slate-800"
					/>

					<div className="font-bold w-full text-center mx-auto text-slate-800 font-wavecondensed text-4xl">
						PROFILE
					</div>

					<IoMdSettings
						onClick={() => {
							setSettingsModalStatus(true);
						}}
						className="text-2xl text-slate-800"
					/>
				</div>

				{profile ? (
					<div
						onScroll={() => {}}
						className="w-full h-t-safe pb-96 flex flex-col justify-between bg-white pattern-polka-gray-500/20 pattern-polka-scale-[.25] rounded-3xl overflow-y-none  max-h-[calc(87vh)]">
						<div className="p-1 pt-1.5 rounded-t-3xl  max-h-[calc(80vh)]">
							{profileImages === null ||
							profileImages?.length === 0 ? (
								<div className="w-full flex items-center justify-between px-2">
									<div className="z-[9000000] flex gap-2">
										<TbLockOff
											onClick={() => {
												setBlockedUsersModalStatus(
													true
												);
											}}
											className="pattern-lines-diagonal-right-gray-100/70 pattern-lines-diagonal-right-scale-[0.6] text-5xl text-white rounded-full p-3  my-auto bg-gray-900 bg-opacity-50"
										/>
									</div>
									<div className="z-[9000000] flex gap-2">
										<MdAddAPhoto
											onClick={() => {
												setEditProfileModalStatus(true);
											}}
											className="pattern-lines-diagonal-right-gray-100/70 pattern-lines-diagonal-right-scale-[0.6] text-5xl text-white rounded-full p-3  my-auto bg-gray-900 bg-opacity-50"
										/>
									</div>
								</div>
							) : null}
							<Swiper
								modules={[
									Navigation,
									Pagination,
									Scrollbar,
									A11y,
								]}
								spaceBetween={50}
								slidesPerView={1}
								pagination={{ clickable: true }}
								scrollbar={{ draggable: true }}
								onSwiper={(swiper) => console.log(swiper)}
								className="h-[calc(55vh)]">
								{profileImages?.map((key, idx) => {
									return (
										<SwiperSlide key={key}>
											<div className="absolute left-2 top-2 z-[9000000] flex gap-2">
												<TbLockOff
													onClick={() => {
														setBlockedUsersModalStatus(
															true
														);
													}}
													className="pattern-lines-diagonal-right-gray-100/70 pattern-lines-diagonal-right-scale-[0.6] text-5xl text-white rounded-full p-3  my-auto bg-gray-900 bg-opacity-50"
												/>
											</div>
											<div className="absolute right-2 top-2 z-[9000000] flex gap-2">
												<MdAddAPhoto
													onClick={() => {
														setEditProfileModalStatus(
															true
														);
													}}
													className="pattern-lines-diagonal-right-gray-100/70 pattern-lines-diagonal-right-scale-[0.6] text-5xl text-white rounded-full p-3  my-auto bg-gray-900 bg-opacity-50"
												/>
											</div>

											<img
												alt="Profile"
												className="z-50 h-full w-full object-cover rounded-3xl shadow-black"
												src={
													profileImages[0] !==
													undefined
														? `https://wave-profile.s3.us-west-1.amazonaws.com/${userProf?.uuid}profile_image${profileImages[idx]}`
														: null
												}
												onError={({
													currentTarget,
												}) => {
													currentTarget.onerror =
														null; // prevents looping
													if (
														imageIndex ===
														profileImages.length
													) {
														setImageIndex(0);
														currentTarget.src =
															profileImages[
																imageIndex
															];
													} else {
														setImageIndex(
															imageIndex + 1
														);
														currentTarget.src =
															profileImages[
																imageIndex
															];
													}
												}}
											/>
										</SwiperSlide>
									);
								})}
							</Swiper>
							<div className="w-full flex text-left text-lg font-light mt-3 z-20 px-3 justify-between">
								<div className="flex">
									<span className="font-semibold text-teal-900">
										{userProf?.first_name},
									</span>
									<span className="ml-1 text-teal-900">
										{CalculateAge(userProf?.birthday)}
									</span>
								</div>
								<div
									className="flex"
									onClick={() => {
										setEditBioModalStatus(true);
									}}>
									<div className="text-teal-900 text-sm">
										Edit Bio
									</div>
									<AiFillEdit className="ml-1 text-teal-900" />
								</div>
							</div>

							<div className="text-teal-900 text-xs font-light my-2 px-3">
								BIO
							</div>
							<p className="px-3 overflow-y-scroll text-teal-900">
								{userProf?.bio || ''}
							</p>
						</div>
					</div>
				) : (
					<LoadingPage></LoadingPage>
				)}
			</IonContent>
		</>
	);
};

export default AccountPage;
