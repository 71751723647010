import './misc.css';

import { useEffect, useRef, useState } from 'react';

import LoadingButton from '../../buttons/LoadingButton';
import ReactCodeInput from 'react-code-input';
import { IonLabel } from '@ionic/react';

const SUPage4 = ({
	IsLoading,
	OnClick,
	AdditionalButtonStyle,
	SetState,
	wrapperStyle,
	headerStyle,
	Value,
}) => {
	const bdDayInput = useRef(null);
	const bdMonthInput = useRef(null);
	const bdYearInput = useRef(null);
	const [goBack, setGoBack] = useState(false);
	const [bdDay, setBdDay] = useState(null);
	const [bdMonth, setBdMonth] = useState(null);
	const [bdYear, setBdYear] = useState(null);

	useEffect(() => {
		bdMonthInput.current.textInput[0].focus();
	}, []);

	const putBdTogether = (vals) => {
		let tg = vals + '-' + bdMonth + '-' + bdDay;
		SetState(tg.toString());
	};

	const updateBdDay = (vals) => {
		setBdDay(vals);
		if (vals.length === 2) {
			bdYearInput.current.textInput[0].focus();
		} else if (vals.length === 0) {
			if (goBack) {
				bdMonthInput.current.textInput[1].focus();
				setGoBack(false);
			} else {
				setGoBack(true);
			}
		}
	};
	const updateBdMonth = (vals) => {
		setBdMonth(vals);
		if (vals.length === 2) {
			bdDayInput.current.textInput[0].focus();
		}
	};
	const updateBdYear = (vals) => {
		setBdYear(vals);
		if (vals.length === 4) {
			putBdTogether(vals);
		} else if (vals.length === 0) {
			if (goBack) {
				bdDayInput.current.textInput[1].focus();
				setGoBack(false);
			} else {
				setGoBack(true);
			}
		}
	};
	useEffect(() => {}, []);

	useEffect(() => {
		if (Value) {
			let test = Value.split('-');

			bdMonthInput.current.textInput[0].value = test[1].charAt(0);
			bdMonthInput.current.textInput[1].value = test[1].charAt(1);

			bdDayInput.current.textInput[0].value = test[2].charAt(0);
			bdDayInput.current.textInput[1].value = test[2].charAt(1);

			bdYearInput.current.textInput[0].value = test[0].charAt(0);
			bdYearInput.current.textInput[1].value = test[0].charAt(1);
			bdYearInput.current.textInput[2].value = test[0].charAt(2);
			bdYearInput.current.textInput[3].value = test[0].charAt(3);
		}
	}, []);

	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>
					My birthday is {'\n'}{' '}
					<span className="text-sm">{'(MM/DD/YYYY)'}</span>
				</div>

				<div className="flex flex-row w-3/4 -mt-2 space-x-4 mx-auto relative">
					<ReactCodeInput
						title={'MM'}
						inputMode="numeric"
						refs={bdMonthInput}
						ref={bdMonthInput}
						placeholder="MM"
						fieldWidth={30}
						type="number"
						className="font-arial mt-4 space-x-1 mx-auto flex my-auto"
						fields={2}
						inputStyle={{
							placeholder: 'black',
							width: '42%',
							color: 'gray',
							textAlign: 'center',
							borderBottomWidth: '1px',
							borderRadius: '0px',
							padding: '8px',
							paddingBottom: '0px',
							outline: 'none',
							borderBottomColor: 'gray',
							background: 'transparent',
							height: '7vh',
						}}
						onChange={updateBdMonth}
						onComplete={() => {
							bdDayInput.current.textInput[0].focus();
						}}
					/>

					<ReactCodeInput
						title={'DD'}
						inputMode="numeric"
						type="number"
						placeholder="DD"
						ref={bdDayInput}
						fieldWidth={30}
						onChange={updateBdDay}
						className="font-arial mt-4 space-x-1 mx-auto flex"
						inputStyle={{
							width: '42%',
							color: 'gray',
							textAlign: 'center',
							borderBottomWidth: '1px',
							borderRadius: '0px',
							padding: '8px',
							paddingBottom: '0px',
							outline: 'none',
							borderBottomColor: 'gray',
							background: 'transparent',
							height: '7vh',
						}}
						fields={2}
						onComplete={() => {
							bdYearInput.current.textInput[0].focus();
						}}
					/>

					<ReactCodeInput
						title={'YYYY'}
						placeholder={'YYYY'}
						type="number"
						inputMode="numeric"
						fieldWidth={30}
						onChange={updateBdYear}
						className="font-arial mt-4 space-x-1 mx-auto"
						inputStyle={{
							width: '22%',
							color: 'gray',
							textAlign: 'center',
							borderBottomWidth: '1px',
							borderRadius: '0px',
							padding: '8px',
							paddingBottom: '0px',
							outline: 'none',
							borderBottomColor: 'gray',
							background: 'transparent',
							height: '7vh',
						}}
						ref={bdYearInput}
						// onComplete={putBdTogether}
						fields={4}
					/>
				</div>
			</>
			<LoadingButton
				IsLoading={IsLoading}
				OnClick={OnClick}
				Message="Continue"
				IsSecondary={false}
				AdditionalButtonStyle={AdditionalButtonStyle}
			/>
		</div>
	);
};

export default SUPage4;
