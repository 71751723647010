import fetch from 'unfetch';
import { BASE_URL } from '../../globals';
/**
 *
 * @param {*} choice the users choice, true or false
 * @returns
 */
const updateVisibility = async (choice) => {
	const url = `${BASE_URL}/api/safety/hide`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			choice: choice,
		}),
	});

	if (res.ok) {
		let responseData = await res.json();

		return { status: true, data: responseData };
	} else {
		let h = await res.json();

		return { status: false };
	}
};

export default updateVisibility;
