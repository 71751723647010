import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
} from 'react-grid-drag';

import PhotoUpload from './PhotoUpload';
import deleteFile from '../../data/profile/delete';
import updateOrder from '../../data/profile/updateOrder';
import upload from '../../data/profile/upload';

const PhotoGrid = ({ profileImages, setProfileImages, User }) => {
	const HandleSetItems = async (position, item) => {
		let tempProfileImages = [...profileImages];
		tempProfileImages[position] = item;

		setProfileImages(tempProfileImages);
		await upload(item, position);
	};

	const HandleDeleteItem = async (position) => {
		let tempProfileImages = [...profileImages];
		tempProfileImages[position] = null;
		setProfileImages(tempProfileImages);
		await deleteFile(profileImages[position]);
	};

	// supply your own state
	// target id will only be set if dragging from one dropzone to another.
	const onChange = async (sourceId, sourceIndex, targetIndex, targetId) => {
		const nextState = swap(profileImages, sourceIndex, targetIndex);
		const arrTwo = nextState.filter(function (el) {
			return el != null;
		});

		for (let i = arrTwo.length; i < 6; i++) {
			arrTwo.push(null);
		}

		setProfileImages(arrTwo);
		await updateOrder(arrTwo);
	};

	return profileImages ? (
		<GridContextProvider onChange={onChange} style={{ zIndex: '9999999' }}>
			<GridDropZone
				id=""
				boxesPerRow={3}
				cols={3}
				rowHeight={170}
				style={{
					gap: '10px',
					padding: '5px',
					height: '400px',
					width: '90%',
					margin: '0 auto',
					marginBottom: '-34px',
					touchAction: 'manipulation',
				}}
				disableDrag={false}
				className="">
				{profileImages?.map((item, key) => {
					let onlyPhoto =
						profileImages.filter((item) => item !== null).length ===
						1;

					return (
						<GridItem
							key={item ? item + key : key}
							style={{
								width: '30%',
								height: '35%',
							}}>
							<PhotoUpload
								setItems={HandleSetItems}
								deleteItem={HandleDeleteItem}
								profilePhoto={item}
								position={key}
								isOnlyPhoto={onlyPhoto}
								User={User}
							/>
						</GridItem>
					);
				})}
			</GridDropZone>
		</GridContextProvider>
	) : (
		<div></div>
	);
};
export default PhotoGrid;
