/* eslint-disable jsx-a11y/alt-text */
// Conventions:
// UM = unmessaged match
// MM = messaged match

import '../css/MatchesPage.css';

import {
	DeepCopyArray,
	RemoveUUIDFromArray,
} from '../components/utils/ArrayFunctions';
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import ConversationCard from '../components/matches/ConversationCard';
import LoadingPage from '../components/ui/LoadingPage';
import MessagingModal from '../components/matches/MessagingModal';
import SavedModal from '../components/matches/SavedModal';
import { chevronDownCircleOutline } from 'ionicons/icons';
import getMatches from '../data/get/getMatches';

const MatchesPage = ({
	convoData,
	setConvoData,
	setMatches,
	matches,
	setShowFooter,
}) => {
	const [isMessagingModalActive, setMessagingModalStatus] = useState(false);
	const [activeUser, setActiveUser] = useState(null);
	const [pullToRefresh, setPullToRefresh] = useState(false);
	const [isSavedModalActive, setSaveModalStatus] = useState(false);

	const elPres = useRef();
	const pageRef = useRef();

	const SetMessagingModal = (user) => {
		setMessagingModalStatus(true);
		setActiveUser(user);
		setShowFooter(false);
	};

	const doRefresh = async (event) => {
		setPullToRefresh(!pullToRefresh);
		await messageDataOnLoad();

		setTimeout(() => {
			event?.detail?.complete();
		}, 2000);
	};

	const messageDataOnLoad = async () => {
		getMatches().then((data) => {
			if (data.status === 'error') {
				console.log('ERROR');
				setMatches(null);
			} else {
				setMatches(data?.data?.userData);
				setConvoData(data?.data?.formattedConversationData);
			}
		});
	};

	const UnmatchFromMMSplice = (uuid) => {
		let newArray = DeepCopyArray(convoData);
		setConvoData(RemoveUUIDFromArray(newArray, uuid));
		doRefresh();
	};

	return (
		<IonContent
			ref={pageRef}
			className="px-safe pb-40"
			color="secondary"
			fullscreen={true}>
			<MessagingModal
				setIsOpen={setMessagingModalStatus}
				pageRef={pageRef}
				isOpen={isMessagingModalActive}
				userObj={convoData}
				Conversation={activeUser}
				SetStatus={setMessagingModalStatus}
				UnmatchFromMMSplice={UnmatchFromMMSplice}
				UpdateMessages={doRefresh}
				setShowFooter={setShowFooter}
			/>

			{!isMessagingModalActive && (
				<>
					<IonRefresher
						slot="fixed"
						className="mt-safe z-50"
						onIonRefresh={doRefresh}>
						<IonRefresherContent
							color={'primary'}
							pullingIcon={chevronDownCircleOutline}
							className=""
							refreshingSpinner={
								'crescent'
							}></IonRefresherContent>
					</IonRefresher>
					<div
						className="w-screen h-screen flex flex-col px-2"
						ref={elPres}>
						{isSavedModalActive === true ? (
							<SavedModal
								isOpen={isSavedModalActive}
								setStatus={setSaveModalStatus}
							/>
						) : null}
						<div className="w-full flex flex-row items-center justify-between pt-safe">
							<div className="text-slate-800 font-bold font-SairaCondensed tracking-[-.05em] text-2xl">
								MATCHES
							</div>
						</div>
						{matches ? (
							<>
								{matches?.length === 0 ? (
									<div className="w-full h-full flex text-center">
										<div className="text-gray-400 text-4xl font-light my-32 mx-auto w-full">
											No matches yet!
										</div>
									</div>
								) : (
									<>
										<div className="flex py-2 pt-2.5 bg-white-dotted shrink-0 space-x-3 overflow-x-scroll w-full items-center rounded-3xl bg-dotted-spacing-[3px] mt-2 bg-dotted-gray-50 hover:bg-dotted-spacing-2 text-gray-900 px-3">
											{convoData?.map((user, idx) => {
												return !user?.has_active_convo ? (
													<div
														key={idx}
														className="text-center flex flex-col my-auto shrink-0"
														onClick={() => {
															SetMessagingModal(
																user
															);
														}}>
														<img
															className="w-16 h-16 object-cover rounded-full"
															src={
																user
																	?.profile_images[0] ===
																undefined
																	? 'https://p.kindpng.com/picc/s/164-1646900_error-free-svg-alarma-de-sismo-hd-png.png'
																	: `https://wave-profile.s3.us-west-1.amazonaws.com/${user?.match_uuid}profile_image${user?.profile_images[0]}`
															}
														/>
														<div className="font-semibold text-gray-800">
															{user?.first_name}
														</div>
													</div>
												) : null;
											})}
										</div>
										<div className="h-full pb-40">
											<div className="mt-2 mb-2 text-sky-900 font-wavecondensed text-lg font-bold tracking-[-.05em]">
												CHATS
											</div>
											{HandleDisplayMessages(
												convoData,
												SetMessagingModal
											)}
										</div>
									</>
								)}
							</>
						) : (
							<LoadingPage></LoadingPage>
						)}
					</div>
				</>
			)}
		</IonContent>
	);
};

const HandleDisplayMessages = (convoData, SetMessagingModal) => {
	let displayConvoData = convoData?.sort(
		(a, b) => b.message_time - a.message_time
	);

	return (
		<div className="px-1 bg-white-dotted rounded-2xl bg-dotted-spacing-[3px] bg-dotted-gray-50 hover:bg-dotted-spacing-2 overflow-y-scroll">
			{displayConvoData?.map((item, idx) => {
				if (item.has_active_convo) {
					return (
						<ConversationCard
							User={item}
							SetMessaging={SetMessagingModal}
							key={idx}
						/>
					);
				} else {
					return null;
				}
			})}
		</div>
	);
};

export default MatchesPage;
