import fetch from 'unfetch';
import { BASE_URL } from '../globals';

const updateUserLocation = async (latitude, longitude) => {
	const url = `${BASE_URL}/api/location/update`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			latitude: latitude,
			longitude: longitude,
		}),
	});

	if (res.ok) {
		// let responseData = await res.json();

		return 0;
	} else {
		let h = await res.json();

		return 1;
	}
};

export default updateUserLocation;
