import {
	IonContent,
	IonModal,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react';

import { BiArrowBack } from 'react-icons/bi';
import LikedByCard from '../components/swipe/LikedByCard';
import SwipePopUp from '../components/swipe/NewSwipe';
import { chevronDownCircleOutline } from 'ionicons/icons';
import getLikedBy from '../data/get/getLikedBy';
import { useEffect } from 'react';

const LikesPage = ({
	setShowFooter,
	likeData,
	setLikeData,
	setMatches,
	matches,
	convoData,
	setConvoData,
	currPage,
	setCurrPage,
	setShowLikedModal,
	showLikedModal,
	doRefresh,
	setMatchToastStatus,
	isProfileModalActive,
	setProfileModalStatus,
	activeUser,
	setSwipeNum,
	swipeNum,
	setActiveUser,
}) => {
	useEffect(() => {
		setShowFooter(!showLikedModal);
	}, [showLikedModal]);

	const HandleRefresh = (event) => {
		getLikedBy().then((resp) => {
			setLikeData(resp.data);
			event?.detail.complete();
		});
	};

	return (
		<IonModal isOpen={showLikedModal}>
			<IonContent scrollY={true}>
				<SwipePopUp
					setShowFooter={setShowFooter}
					doRefresh={HandleRefresh}
					setMatchToastStatus={setMatchToastStatus}
					isOpen={isProfileModalActive}
					SetStatus={setProfileModalStatus}
					User={activeUser}
					SetSwipeNum={setSwipeNum}
					SwipeNum={swipeNum}
					showLikeOptions={true}
				/>
				<IonRefresher
					slot="fixed"
					className="mt-12 z-50"
					onIonRefresh={HandleRefresh}>
					<IonRefresherContent
						color={'primary'}
						pullingIcon={chevronDownCircleOutline}
						className=""
						refreshingSpinner={'crescent'}></IonRefresherContent>
				</IonRefresher>
				<BiArrowBack
					onClick={() => {
						setShowLikedModal(false);
					}}
					className="absolute top-12 left-8 bg-gray-600 rounded-lg px-1 text-4xl text-gray-100"
				/>
				<div className="flex flex-col w-full mt-12">
					<div className="w-3/4 mx-auto text-4xl text-center font-light italic text-wave-blue mb-4 font-pacifico">
						Likes
					</div>
					<div className="grid grid-cols-2 w-3/4 mx-auto gap-x-4">
						{likeData?.map((user, idx) => {
							return (
								<LikedByCard
									key={idx}
									first_name={user.first_name}
									profile_images={user?.profile_images}
									uuid={user.uuid}
									SetProfileModal={setProfileModalStatus}
									User={user}
									setActiveUser={setActiveUser}
								/>
							);
						})}
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};
export default LikesPage;
