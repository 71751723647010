import fetch from 'unfetch';
import { BASE_URL } from '../../globals';
/**
 *
 * @returns
 */
const getBlocked = async () => {
	const url = `${BASE_URL}/api/safety/getBlocked`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
	});

	if (res.ok) {
		let responseData = await res.json();

		return { status: true, data: responseData };
	} else {
		let h = await res.json();

		return { status: false };
	}
};

export default getBlocked;
