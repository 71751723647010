import {
	IonContent,
	IonRefresher,
	IonRefresherContent,
	IonToast,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import LikesPage from './LikesPage';
import LoadingPageTwo from '../components/ui/LoadingPageTwo';
import { RemoveUUIDFromArray } from '../components/utils/ArrayFunctions';
import SafetyOptions from '../components/safety/blockActionSheet';
import SwipeCard from '../components/swipe/SwipeCard';
import SwipePopUp from '../components/swipe/NewSwipe';
import blockUser from '../data/safety/block';
import { chevronDownCircleOutline } from 'ionicons/icons';
import getLikedBy from '../data/get/getLikedBy';
import getSaved from '../data/get/getSaved';
import getUsers from '../data/get/getUsers';
import like from '../data/swipe/like';
import save from '../data/swipe/save';
import updateUserLocation from '../data/addLocation';
import WaveLoadingAnimation from '../components/utils/WaveLoadingAnimation';
import VisibilityWarning from '../components/ui/VisbilityWarning';
// import verifyUser from '../data/auth/verifyUser';

const Home = ({
	loadedUsers,
	setLoadedUsers,
	usersToSwipe,
	setUsersToSwipe,
	userProf,
	setSavedUsers,
	setShowWarning,
	showWarning,
	setShowFooter,
	setLikedBy,
	likedBy,
	matches,
	setMatches,
	addWatcherBg,
	userLat,
	userLon,
	setUserLat,
	setUserLon,
}) => {
	const [showActionSheet, setShowActionSheet] = useState(false);
	const [userToBlock, setUserToBlock] = useState(null);
	const [toastGeneral, setToastGeneral] = useState(false);
	const [toastGeneralMsg, setToastGeneralMsg] = useState('');
	const [swipeNum, setSwipeNum] = useState(0);
	const [isProfileModalActive, setProfileModalStatus] = useState(false);
	const [activeUser, setActiveUser] = useState(null);
	const [isMatchToastActive, setMatchToastStatus] = useState(false);
	const [isSaveToast, setIsSaveToast] = useState(false);
	const [showLikedModal, setShowLikedModal] = useState(false);
	const getUserLoc = async () => {
		let options = {
			maximumAge: 3000,
			enableHighAccuracy: true,
		};
		const resp = {};
		console.log(userLat);
		console.log(userLon);
		const respLatString = userLat;
		const respLongString = userLon;
		setUserLat(respLatString);
		setUserLon(respLongString);
		console.log(respLatString);
		console.log(respLongString);
	};

	useEffect(() => {
		// testPermissions();
		// verifyUser().then((data) => {
		// 	if (data.status === 'error') {
		// 		localStorage.clear();
		// 		window.location = '/welcome';
		// 	}
		getUserLoc().then((data) => {});
		// });
	}, []);

	useEffect(() => {
		updateUserLocation(userLat, userLon).then((data) => {
			getUsers(userLat, userLon).then((data) => {
				console.log(data);
				if (data.status) {
					setLoadedUsers(true);
					if (data.data[0]) {
						setUsersToSwipe(data.data);
					} else {
					}
				}
			});
		});
	}, [userLat, userLon, isProfileModalActive]);

	const updateSwipeArr = async (arr) => {
		await setUsersToSwipe(arr);
	};

	const doRefresh = async (event) => {
		if (userLat && userLon) {
			updateUserLocation(userLat, userLon).then((data) => {
				if (userLat && userLon) {
					getUsers(userLat, userLon).then((data) => {
						if (data.status) {
							setLoadedUsers(true);

							setUsersToSwipe(data.data);
						}
					});
				}
			});
		}
		getLikedBy().then((data) => {
			setLikedBy(data.data);
		});

		getSaved().then((data) => {
			if (data?.data?.status === 'succ') {
				setSavedUsers(data?.data?.data);
			}
		});
		setTimeout(() => {
			event?.detail.complete();
		}, 4000);
	};

	const BlockUser = (userToBlock) => {
		blockUser(userToBlock).then(() => {
			let newUserList = RemoveUUIDFromArray(usersToSwipe, userToBlock);
			setUsersToSwipe(newUserList);
		});
	};
	useEffect(() => {
		if (userProf?.isVisible) {
			setShowWarning(false);
		} else {
			setShowWarning(true);
		}
	}, [userProf]);

	const HandleDislike = async (ix) => {
		const index = ix;
		const toDislikeUUID = usersToSwipe[ix]?.uuid;
		let newDStuff = usersToSwipe;
		newDStuff.splice(index, 1);
		await updateSwipeArr(newDStuff);

		await like(toDislikeUUID, false);

		const fetchAllUsers = await getUsers(userLat, userLon);
		if (fetchAllUsers.status) {
			setToastGeneralMsg('User disliked!');
			setUsersToSwipe(fetchAllUsers.data);
			setLoadedUsers(true);
		}
	};
	const HandleSave = async (ix) => {
		const toSaveUUID = usersToSwipe[ix]?.uuid;
		let newDStuff = usersToSwipe;
		newDStuff.splice(ix, 1);
		await updateSwipeArr(newDStuff);

		await save(toSaveUUID);

		const fetchAllUsers = await getUsers(userLat, userLon);

		if (fetchAllUsers.status) {
			setToastGeneralMsg('User saved for 24 hours!');
			setUsersToSwipe(fetchAllUsers.data);
			setLoadedUsers(true);
		}
	};

	const HandleLike = async (ix) => {
		const index = ix;
		const toLikeUUID = usersToSwipe[ix]?.uuid;
		const newDStuff = usersToSwipe;
		newDStuff.splice(index, 1);
		await updateSwipeArr(newDStuff);

		let postLikeData = await like(toLikeUUID, true);
		console.log(postLikeData);
		if (postLikeData?.data?.isMatch === true) {
			setToastGeneralMsg('Matched!');
			setMatchToastStatus(true);
		} else {
			setToastGeneralMsg('Wave sent!');
			setToastGeneral(true);
		}
	};

	const HandleNSDislike = async (uuid) => {
		const toDislikeUUID = uuid;

		await like(toDislikeUUID, false);

		const fetchAllUsers = await getUsers(userLat, userLon);
		if (fetchAllUsers.status) {
			setToastGeneralMsg('User disliked!');
			setUsersToSwipe(fetchAllUsers.data);
			setLoadedUsers(true);
		}
		await doRefresh();
		setProfileModalStatus(false);
	};
	const HandleNSSave = async (uuid) => {
		const toSaveUUID = uuid;

		await save(toSaveUUID);

		const fetchAllUsers = await getUsers(userLat, userLon);

		if (fetchAllUsers.status) {
			setToastGeneralMsg('User saved for 24 hours!');
		}
		await doRefresh();
		setProfileModalStatus(false);
	};

	const HandleNSLike = async (uuid) => {
		let postLikeData = await like(uuid, true);

		if (postLikeData?.data?.isMatch === true) {
			setToastGeneralMsg('Matched!');
			setMatchToastStatus(true);
		} else {
			setToastGeneralMsg('Wave sent!');
			setToastGeneral(true);
		}
		await doRefresh();
		setProfileModalStatus(false);
	};
	const homeRef = useRef();
	return (
		<>
			<IonContent
				ref={homeRef}
				className="ion-padding bg-wave-bg "
				color="secondary"
				scrollY={true}>
				{showWarning && userProf && (
					<div className="w-full h-full flex flex-col">
						<div className="flex mx-auto h-1/3 flex-col">
							<WaveLoadingAnimation></WaveLoadingAnimation>
							<div className="mx-auto text-center font-wave -mt-24 text-4xl w-4/5 text-slate-800">
								You are currently not visible on the wave.
								<br></br>
								<br></br>
								<span className="font-light text-xl">
									Toggle in settings to see people around you.
								</span>
							</div>
						</div>
					</div>
				)}
				{loadedUsers && !showWarning ? (
					<>
						<IonRefresher
							slot="fixed"
							className="mt-safe z-50"
							onIonRefresh={doRefresh}>
							<IonRefresherContent
								color={'primary'}
								pullingIcon={chevronDownCircleOutline}
								className=""
								refreshingSpinner={
									'crescent'
								}></IonRefresherContent>
						</IonRefresher>
						<div className="absolute w-11/12 flex t-safe font-bold text-slate-800 font-wavecondensed text-4xl">
							<span className="mx-auto">THE WAVE</span>
						</div>
						<SwipePopUp
							isOpen={isProfileModalActive}
							doRefresh={doRefresh}
							SetStatus={setProfileModalStatus}
							User={activeUser}
							showLikeOptions={true}
							setShowFooter={setShowFooter}
							BlockUser={BlockUser}
							HandleSave={HandleNSSave}
							HandleLike={HandleNSLike}
							HandleDislike={HandleNSDislike}
						/>

						<LikesPage
							showLikedModal={showLikedModal}
							setShowFooter={setShowFooter}
							setShowLikedModal={setShowLikedModal}
							matches={matches}
							setMatches={setMatches}
							setLikeData={setLikedBy}
							likeData={likedBy}
							doRefresh={doRefresh}
							setMatchToastStatus={setMatchToastStatus}
							isProfileModalActive={isProfileModalActive}
							setProfileModalStatus={setProfileModalStatus}
							activeUser={activeUser}
							setSwipeNum={setSwipeNum}
							swipeNum={swipeNum}
							setActiveUser={setActiveUser}
						/>

						{usersToSwipe && usersToSwipe.length !== 0 ? (
							<div className="w-full pt-safe pb-32">
								{usersToSwipe.map((u, ix) => {
									return (
										<div
											key={u.id}
											className=" flex flex-col h-1/2 mx-auto mt-0 -mb-6">
											<SwipeCard
												HandleSave={HandleSave}
												setUsersToSwipe={
													setUsersToSwipe
												}
												userLat={userLat}
												userLon={userLon}
												setLoadedUsers={setLoadedUsers}
												setIsSaveToast={setIsSaveToast}
												u={u}
												setShowActionSheet={
													setShowActionSheet
												}
												setActiveUser={setActiveUser}
												showActionSheet={
													showActionSheet
												}
												doRefresh={doRefresh}
												userToBlock={userToBlock}
												SetProfileModal={
													setProfileModalStatus
												}
												name={
													usersToSwipe[ix]?.first_name
												}
												profile_image={
													usersToSwipe[ix]
														?.profile_images[0]
												}
												bio={usersToSwipe[ix]?.bio}
												setSwipeNum={setSwipeNum}
												uuid={usersToSwipe[ix]?.uuid}
												birthday={
													usersToSwipe[ix]?.birthday
												}
												swipeNum={ix}
												setUserToBlock={setUserToBlock}
												HandleDislike={HandleDislike}
												HandleLike={HandleLike}
												ix={ix}
											/>
										</div>
									);
								})}
							</div>
						) : (
							<div className="h-t-safe flex flex-col justify-center">
								<div className="font-medium text-gray-600 w-3/4 mx-auto text-center text-lg font-wave-condensed">
									Looks like the surf is a little flat!{' '}
									<br></br>
									<br></br>
									<br></br>
									Don’t worry — you’ll be back on{' '}
									<span className="text-slate-800">
										THE WAVE
									</span>{' '}
									soon enough!
								</div>
								<div className="w-2/3 mx-auto text-center">
									<button
										onClick={(e) => {
											setLoadedUsers(false);
										}}
										className="bg-slate-800 px-8 py-2 mx-auto mt-16 rounded-lg text-xl">
										Refresh
									</button>
								</div>
							</div>
						)}

						<IonToast
							isOpen={isMatchToastActive}
							message={'Congratulation! New Match'}
							duration={4000}
							onWillDismiss={() => {
								setMatchToastStatus(false);
							}}
							color="primary"
							position="top"
						/>
						<IonToast
							isOpen={toastGeneral}
							message={toastGeneralMsg}
							duration={4000}
							onWillDismiss={() => {
								setToastGeneral(false);
							}}
							color="primary"
							position="top"
						/>
						<IonToast
							isOpen={isSaveToast}
							message={'User saved for 24 hours!'}
							duration={4000}
							onWillDismiss={() => {
								setIsSaveToast(false);
							}}
							color="primary"
							position="top"
						/>
					</>
				) : (
					<LoadingPageTwo />
				)}
				<SafetyOptions
					setShowActionSheet={setShowActionSheet}
					showActionSheet={showActionSheet}
					userToBlock={userToBlock}
					blockUser={BlockUser}
					doRefresh={doRefresh}
				/>
			</IonContent>
		</>
	);
};

export default Home;
