import LoadingButton from '../../buttons/LoadingButton';
import TelInput from '../../inputs/TelInput';

const SUPage1 = ({
	wrapperStyle,
	headerStyle,
	AdditionalInputWrapperStyle,
	SetState,
	AdditionalButtonStyle,
	IsLoading,
	OnClick,
}) => {
	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>
					Lets get started with a little info!
				</div>
				<TelInput
					Placeholder={'Phone Number'}
					SetState={SetState}
					AdditionalWrapperStyle={AdditionalInputWrapperStyle}
				/>
			</>
			<LoadingButton
				IsLoading={IsLoading}
				OnClick={OnClick}
				Message="Continue"
				IsSecondary={false}
				AdditionalButtonStyle={AdditionalButtonStyle}
			/>
		</div>
	);
};

export default SUPage1;
