import errorImage from '../images/error_image.png';

const LikedByCard = ({
	first_name,
	profile_images,
	uuid,
	SetProfileModal,
	User,
	setActiveUser,
}) => {
	return (
		<div
			onClick={() => {
				setActiveUser(User);
				SetProfileModal(true);
			}}
			className="flex flex-col w-1/2 shrink-0 items-center p-3 border-gray-300 relative">
			<img
				alt="tag"
				className="w-full h-40 rounded-xl object-cover"
				src={`https://wave-profile.s3.us-west-1.amazonaws.com/${uuid}profile_image${profile_images[0]}`}
				onError={({ currentTarget }) => {
					currentTarget.onerror = null; // prevents looping
					currentTarget.src = errorImage;
				}}
			/>
			<div className="text-slate-800 text-xl mt-1 font-medium">
				{first_name}
			</div>
		</div>
	);
};
export default LikedByCard;
