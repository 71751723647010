const SUStatusIndicator = ({ suIndex }) => {
	const notSelected =
		'w-1/3 border border-2 border-wave-inactive bg-wave-inactive ';
	const selected = 'w-1/3 border border-2 border-wave-blue bg-wave-blue';
	return (
		<div className="flex mb-40 z-50 w-full px-6 rounded-lg transition-all duration-1000">
			{suIndex >= 0 ? (
				<span className={selected + ' rounded-lg'}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 1 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 2 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 3 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 4 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 5 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 6 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
			{suIndex >= 7 ? (
				<span className={selected}></span>
			) : (
				<span className={notSelected}></span>
			)}
		</div>
	);
};

export default SUStatusIndicator;
