import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} userToMessage
 * @param {*} convoId
 * @returns
 */

const getMessages = async (userToMessage, convoId) => {
	const url = `${BASE_URL}/api/messages/get`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			userToMessage: userToMessage,
			convoId: convoId,
		}),
	});

	if (res.ok) {
		let responseData = await res.json();

		return { status: 'success', responseData: responseData };
	} else {
		let responseData = await res.json();

		return { status: 'error', responseData: responseData };
	}
};
export default getMessages;
