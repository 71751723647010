/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useRef, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { ImSpinner8 } from 'react-icons/im';
import { MdAddAPhoto } from 'react-icons/md';
import heic2any from 'heic2any';
import compressImage from '../../data/compress';

const PhotoUpload = ({
	profilePhoto,
	position,
	setItems,
	deleteItem,
	isOnlyPhoto,
	User,
}) => {
	const fileInputRef = useRef();
	const [loading, setLoading] = useState(false);
	const [preview, setPreview] = useState(null);
	const [image, setImage] = useState(null);
	const [canDelete, setCanDelete] = useState(true);

	const HandlePhoto = async () => {
		if (profilePhoto) {
			let isNotUploaded = profilePhoto.startsWith('data:image');
			if (!isNotUploaded) {
				setPreview(
					`https://wave-profile.s3.us-west-1.amazonaws.com/${User?.uuid}profile_image${profilePhoto}`
				);
			} else {
				setPreview(profilePhoto);
			}
		} else if (image) {
			setLoading(true);
			if (image.name.includes('.HEIC')) {
				// get image as blob url
				let blobURL = URL.createObjectURL(image);

				// convert "fetch" the new blob url
				let blobRes = await fetch(blobURL);

				// convert response to blob
				let blob = await blobRes.blob();

				// convert to PNG - response is blob
				let conversionResult = await heic2any({ blob });
				const compressedImage = await compressImage(conversionResult);

				let h;
				const reader = new FileReader();
				reader.onloadend = () => {
					h = reader.result;

					setPreview(reader.result);
					setItems(position, h);
					setCanDelete(true);
				};

				reader.readAsDataURL(compressedImage);
			} else {
				const compressedImage = await compressImage(image);

				let h;
				const reader = new FileReader();
				reader.onloadend = () => {
					h = reader.result;

					setPreview(reader.result);
					setItems(position, h);
					setCanDelete(true);
				};

				reader.readAsDataURL(compressedImage);
			}
			setLoading(false);
		} else {
			setLoading(true);
			setPreview(null);
			setLoading(false);
		}
	};

	useEffect(() => {
		HandlePhoto();
	}, [profilePhoto, image]);

	const inputWrapperStyle =
		'w-11/12 mx-auto h-full flex border border-sky-900 relative my-auto h-full rounded-lg';

	return (
		<div className={inputWrapperStyle}>
			{preview || profilePhoto ? (
				<>
					{!loading ? (
						<img
							className="object-cover h-full w-full rounded-lg"
							src={preview}
							onClick={(event) => {
								event.preventDefault();
								fileInputRef.current.click();
							}}
						/>
					) : (
						<>
							<ImSpinner8 className="absolute top-0 right-0 left-0 text-2xl bottom-0 my-auto mx-auto animate-spin text-slate-800" />
							<img
								className="object-cover h-full w-full"
								src={preview}
								onClick={(event) => {
									event.preventDefault();
									fileInputRef.current.click();
								}}
							/>
						</>
					)}
					{canDelete && !isOnlyPhoto ? (
						<AiOutlineClose
							className="absolute -top-3 -right-3 text-3xl bg-red-300 p-2 rounded-full text-red-700"
							onClick={async () => {
								setPreview(null);
								deleteItem(position);
							}}
						/>
					) : null}
				</>
			) : (
				<button
					className="flex flex-col items-center justify-center h-full w-full text-center"
					onClick={(event) => {
						event.preventDefault();
						fileInputRef?.current?.click();
					}}>
					{!loading ? (
						<MdAddAPhoto className="text-slate-800 text-2xl" />
					) : (
						<ImSpinner8 className="text-2xl animate-spin text-slate-800" />
					)}
				</button>
			)}
			<input
				ref={fileInputRef}
				type="file"
				accept="image/x-png,image/gif,image/jpeg,image/heic"
				className="hidden"
				onChange={(event) => {
					const file = event.target.files[0];
					if (file && file.type.substring(0, 5) === 'image') {
						setImage(file);
					} else {
						setImage(null);
					}
				}}
			/>
		</div>
	);
};

export default PhotoUpload;
