/* eslint-disable jsx-a11y/alt-text */
import { IonContent, IonModal, IonToast } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import SwipePopUp from '../swipe/NewSwipe';
import getSaved from '../../data/get/getSaved';
import ModalBackButton from '../ui/ModalBackButton';

const SavedModal = ({ isOpen, setStatus }) => {
	const modal = useRef();
	const [savedUsers, setSavedUsers] = useState(null);
	const [currUser, setCurrUser] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isMatchToastActive, setMatchToastStatus] = useState(false);

	const placeHolderFunc = () => {
		//This func exists because the swipe modal requires a "show footer bar variable."
	};
	const getSavedUsers = async () => {
		const data = await getSaved();
		if (data?.data?.status === 'succ') {
			setSavedUsers(data?.data?.data);
		}
	};
	useEffect(() => {
		getSavedUsers();
	}, []);
	const doRefresh = async (e) => {
		let savedUsers = await getSavedUsers();

		if (savedUsers?.data?.status === 'succ') {
			setSavedUsers(savedUsers?.data?.data);
		}
	};
	return (
		<IonModal
			ref={modal}
			isOpen={isOpen}
			onDidDismiss={() => {
				modal.current.dismiss().then(() => {
					setStatus(false);
				});
			}}
			className="flex flex-col z-10">
			<IonToast
				isOpen={isMatchToastActive}
				message={'Congratulation! New Match'}
				duration={4000}
				onWillDismiss={() => {
					setMatchToastStatus(false);
				}}
				color="primary"
				position="top"
			/>

			<SwipePopUp
				doRefresh={doRefresh}
				isOpen={showModal}
				setShowFooter={placeHolderFunc}
				SetStatus={setShowModal}
				setMatchToastStatus={setMatchToastStatus}
				User={currUser}
				showLikeOptions={true}></SwipePopUp>

			<IonContent className="">
				<div className="pt-16 relative">
					<ModalBackButton modal={modal} setStatus={setStatus} />

					<div className="w-full flex flex-col items-center mt-8 justify-between text-sky-700">
						<span className="font-pacifico text-2xl">
							Saved Users
						</span>
						<span className="w-3/4 text-md font-light text-center">
							Check out users you've wave'd n save'd! After 24
							hours they will dissapear!
						</span>
					</div>
					<div className=" grid grid-cols-2 w-11/12 mx-auto mt-8 gap-x-2">
						{savedUsers !== null
							? savedUsers?.map((key, idx) => {
									return (
										<div
											key={idx}
											className="mx-auto bg-teal-500 rounded-lg w-full mt-4 backdrop-blur-xl bg-opacity-30"
											onClick={() => {
												setCurrUser(key);
												setShowModal(true);
											}}>
											<img
												className=" object-cover rounded-lg py-1 px-1"
												src={`https://wave-profile.s3.us-west-1.amazonaws.com/${key?.uuid}profile_image${key?.profile_images[0]}`}></img>
											<div className="text-xl font-light text-gray-800 text-center">
												{key?.first_name}
											</div>
											{/* <div className="text-sm font-light text-gray-400 text-center w-2/3 mx-auto">
												{key?.bio}
											</div> */}
										</div>
									);
							  })
							: null}
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};

export default SavedModal;
