import { useEffect, useRef } from 'react';

import LoadingButton from '../../buttons/LoadingButton';
import SUPhotoGrid from './SUPhotoGrid';
import { createGesture } from '@ionic/react';

const SUPage7 = ({
	wrapperStyle,
	headerStyle,
	IsLoading,
	OnClick,
	AdditionalButtonStyle,
	profileImages,
	setProfileImages,
}) => {
	const cardRef = useRef();

	useEffect(() => {
		if (cardRef.current) {
			gestureInit();
		}
	}, [cardRef]);

	const gestureInit = () => {
		const card = cardRef.current;
		if (card) {
			const reorderGesture = createGesture({
				el: cardRef.current,
				gestureName: 'drag-reorder',
				onMove: (detail) => {
					card.style.transform = `translateX(${detail.deltaX}px) translateY(${detail.deltaY}px)`;
				},
				onEnd: (detail) => {
					card.style.transition =
						'0.25s cubic-bezier(.175, .885, .32, 1.275)';

					card.style.transform = '';
				},
			});
			reorderGesture.enable();
		}
	};

	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle + ' mt-10'}>Upload Photos</div>
				<div className="z-50">
					<SUPhotoGrid
						profileImages={profileImages}
						setProfileImages={setProfileImages}
					/>
				</div>

				<LoadingButton
					IsLoading={IsLoading}
					OnClick={OnClick}
					Message="Continue"
					IsSecondary={false}
					AdditionalButtonStyle={AdditionalButtonStyle}
				/>
			</>
		</div>
	);
};

export default SUPage7;
