import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 * @param {*} phoneNumber The users phone number
 * @param {*} password The users password
 * @returns status:"success" || status:"error"
 */
const confirmLogin = async (code, ph) => {
	const url = `${BASE_URL}/api/login/verifysms`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			code: code,
			ph: ph,
		}),
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		localStorage.setItem('userData', JSON.stringify(await res.json()));
		localStorage.setItem('auth', responseData.jwt);
		return { status: 'success' };
	} else {
		return { status: 'error' };
	}
};
export default confirmLogin;
