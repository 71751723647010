import { IonContent, IonModal, IonPage, IonToast } from '@ionic/react';
import { useRef, useState } from 'react';

import { BiArrowBack } from 'react-icons/bi';
import LoadingButton from './buttons/LoadingButton';
import getProfile from '../data/profile/get';
import updatebio from '../data/profile/updatebio';

const EditBioModal = ({ isOpen, SetStatus, Bio, setUserProf }) => {
	// SET BIO
	const modal = useRef();
	const textRef = useRef();
	const [wordCount, setWordCount] = useState(Bio?.length || 0);
	const [isLoading, setLoadingStatus] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const UpdateBio = async () => {
		setLoadingStatus(true);
		await updatebio(textRef.current.value);
		setErrorMessage('Bio Updated');
		getProfile().then((data) => {
			setUserProf(data);
		});
		modal.current.dismiss().then(() => {
			SetStatus(false);
		});
		setLoadingStatus(false);
	};

	return (
		<IonModal
			ref={modal}
			isOpen={isOpen}
			onDidDismiss={() => {
				// SET BIO
				modal.current.dismiss().then(() => {
					SetStatus(false);
				});
			}}>
			<IonContent color="secondary" scrollY={false}>
				<BiArrowBack
					className="back-arrow mt-safe"
					onClick={() => {
						modal.current.dismiss().then(() => {
							SetStatus(false);
						});
					}}
				/>
				<div className="w-full flex flex-col items-center h-full mt-safe bg-white pattern-polka-gray-500/20 pattern-polka-scale-[.25] rounded-t-3xl">
					<div className="font-bold w-full mt-2 text-center mx-auto text-slate-800 font-wavecondensed text-4xl">
						EDIT BIO
					</div>
					<div className="flex flex-col w-2/3">
						<div className="w-full flex items-center justify-between">
							<div className="text-teal-900 text-sm mt-10">
								BIO
							</div>
							<div className="text-teal-900 text-sm mt-10">
								{wordCount}/300
							</div>
						</div>
						<textarea
							ref={textRef}
							onChange={(e) => {
								setWordCount(e.target.value.length);
							}}
							className="border p-2 text-slate-800 focus:outline-none h-[calc(200px)] border-slate-800 w-full mx-auto mt-1 bg-transparent"
							placeholder="Bio"
							maxLength={300}
							defaultValue={Bio || null}
						/>
					</div>
					<LoadingButton
						OnClick={UpdateBio}
						Message={'Save'}
						AdditionalButtonStyle={'mt-2 px-2'}
						IsLoading={isLoading}
					/>
				</div>
			</IonContent>
		</IonModal>
	);
};

export default EditBioModal;
