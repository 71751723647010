import '../css/WelcomePage.css';
import '../theme/layout.css';
import '../theme/text.css';
import '../theme/general.css';
import '../theme/inputs.css';

import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import LoginModal from '../components/welcome/LoginModal';
import SignUpModal from '../components/welcome/SignUpModal';
import { useHistory } from 'react-router';
import verifyUser from '../data/auth/verifyUser';
import wave_surfer from '../components/images/wave-surfer1.png';

const WelcomePage = () => {
	let history = useHistory();

	const pageRef = useRef();

	const [needsToSignIn, setNeedsToSignIn] = useState(true);
	const [isLIModalActive, setLIModalStatus] = useState(false);
	const [isSUModalActive, setSUModalStatus] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState(false);
	const HandleLIModalDismiss = () => {
		setLIModalStatus(false);
	};

	const HandleLIModalShow = () => {
		setLIModalStatus(true);
	};

	const HandleSUModalShow = () => {
		setSUModalStatus(true);
	};

	const HandleSUModalHide = () => {
		setSUModalStatus(false);
	};

	useEffect(() => {
		verifyUser().then((data) => {
			if (data.status === 'success') {
				history.push('/home');
			} else {
				setLoadingStatus(false);
				localStorage.clear();
				setNeedsToSignIn(true);
			}
		});
		setTimeout(() => {
			setLoadingStatus(false);
			setNeedsToSignIn(true);
		}, 5000);
	}, []);

	return (
		<IonPage ref={pageRef}>
			<IonContent
				className="w-full h-screen flex flex-col relative"
				scrollY={false}>
				<div className="w-full h-screen flex flex-col bg-dotted-spacing-[3px] bg-dotted-gray-50">
					<LoginModal
						isOpen={isLIModalActive}
						onDidDismiss={HandleLIModalDismiss}
					/>

					<SignUpModal
						isOpen={isSUModalActive}
						onDidDismiss={HandleSUModalHide}
						setModalStatus={setSUModalStatus}
					/>

					<div className="absolute text-center w-full t-safe text-5xl text-wave-text font-bold">
						THE WAVE
					</div>
					<div className="w-full h-full -mt-12 mx-auto my-auto flex">
						<img
							alt="loading page"
							src={wave_surfer}
							className="w-full left-8 z-10 mx-auto my-auto"
						/>
					</div>
					{!loadingStatus ? (
						<>
							<div className="flex w-full flex-col z-50 mt-12 absolute b-safe">
								<button
									className="w-11/12 mx-auto bg-teal-700 bg-opacity-80 text-white font-bold text-xl backdrop-filter backdrop-blur-sm border-2 rounded-full py-4"
									expand="block"
									fill="outline"
									shape="round"
									onClick={HandleSUModalShow}>
									Sign Up
								</button>
								<div
									className="text-center border text-xl border-teal-500 w-11/12 mx-auto text-teal-600 font-semibold rounded-full py-4 mt-6"
									onClick={HandleLIModalShow}>
									Login
								</div>
							</div>
						</>
					) : null}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default WelcomePage;
