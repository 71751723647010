import { useEffect, useState } from 'react';

import { BASE_URL } from '../../globals';
import fetch from 'unfetch';

/**
 *
 * @param {*} userToLike the uuid of the user to like
 * @param {*} decision true or false for like or dislike
 * @returns status: error or status:success. If success, it will return data with a boolean (didMatch)!
 */
const like = async (userToLike, decision) => {
	console.log('LIKE CALLED');
	const url = `${BASE_URL}/api/swipe/like`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			userToLike: userToLike,
			decision: decision,
		}),
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		return { status: 'success', data: responseData };
	} else {
		return { status: 'error' };
	}
};
export default like;
