import { IonContent, IonModal, IonPage } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import { BiArrowBack } from 'react-icons/bi';
import { ImSpinner8 } from 'react-icons/im';
import { RemoveUUIDFromArray } from './utils/ArrayFunctions';
import getBlocked from '../data/safety/getBlocked';
import unBlockUser from '../data/safety/unblock';

const BlockedUsersModal = ({ isOpen, SetStatus, PresentingElement }) => {
	const modal = useRef();
	const [blockedUsers, setBlockedUsers] = useState(null);
	const [unblockLoading, setUnblockLoading] = useState(false);

	useEffect(() => {
		getBlocked().then((data) => {
			setBlockedUsers(data?.data?.data);
		});
	}, []);

	return (
		<IonModal
			ref={modal}
			isOpen={isOpen}
			PresentingElement={PresentingElement}
			onDidDismiss={() => {
				SetStatus(false);
			}}>
			<IonContent color="secondary" className="">
				<BiArrowBack
					className="back-arrow mt-safe"
					onClick={() => {
						SetStatus(false);
					}}
				/>
				<div className="w-full flex flex-col items-center h-full pt-safe">
					<div className="font-bold w-full mt-2 text-center mx-auto text-slate-800 font-wavecondensed text-4xl">
						BLOCKED USERS
					</div>
					{blockedUsers ? (
						<>
							{blockedUsers[0] !== undefined ? (
								<div className="flex flex-col mt-6 w-full pb-safe overfolw-y-scroll">
									{blockedUsers?.map((blockedUser, key) => {
										key = { key };
										let isLast =
											blockedUsers?.length - 1 ===
											key?.key;
										console.log(
											'BLOCKED USER',
											blockedUser
										);
										return (
											<div>
												<div className="text-black py-4 flex flex-rows w-3/4 mx-auto">
													<div className="flex w-2/3">
														<div className="flex flex-shrink-0 bg-wave-blue rounded-full h-12 w-12">
															<span className="mx-auto my-auto text-white">
																{
																	blockedUser
																		?.first_name[0]
																}
															</span>
														</div>
														<div className="my-auto px-4">
															{
																blockedUser?.first_name
															}
														</div>
													</div>
													<div className="w-1/3 my-auto text-md">
														<span
															className="border-0 border-gray-200 rounded-md px-4 py-2 bg-wave-blue text-white w-20"
															onClick={() => {
																if (
																	unblockLoading ===
																	false
																) {
																	setUnblockLoading(
																		key.key
																	);

																	unBlockUser(
																		blockedUser?.uuid
																	).then(
																		(
																			data
																		) => {
																			let newBlockedUserArray =
																				RemoveUUIDFromArray(
																					blockedUsers,
																					blockedUser?.uuid
																				);

																			setBlockedUsers(
																				newBlockedUserArray
																			);

																			setUnblockLoading(
																				false
																			);
																		}
																	);
																}
															}}>
															{unblockLoading !==
															key.key ? (
																<div className="inline-block w-16 text-center">
																	Unblock
																</div>
															) : (
																<ImSpinner8 className="animate-spin text-white text-mg inline-block w-16 -mt-1 text-center" />
															)}
														</span>
													</div>
												</div>
												{!isLast ? (
													<hr className="w-1/2 mx-auto" />
												) : null}
											</div>
										);
									})}
								</div>
							) : (
								<div className="my-auto mx-auto text-xl font-light text-gray-800 ">
									No Blocked Users!
								</div>
							)}
						</>
					) : (
						<div className="my-auto">
							<ImSpinner8 className="animate-spin text-wave-blue text-4xl " />
						</div>
					)}
				</div>
			</IonContent>
		</IonModal>
	);
};

export default BlockedUsersModal;
