import fetch from 'unfetch';
import { BASE_URL } from '../../globals';
/**
 *
 * @param {*} toBlock uuid of person to block
 * @returns
 */
const blockUser = async (toBlock) => {
	const url = `${BASE_URL}/api/safety/block`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			toBlock: toBlock,
		}),
	});

	if (res.ok) {
		let responseData = await res.json();

		return 0;
	} else {
		let h = await res.json();

		return 1;
	}
};

export default blockUser;
