/* eslint-disable jsx-a11y/alt-text */
import './MessageModal.css';

import {
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonPage,
	IonPopover,
	IonTextarea,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import { AiOutlineEllipsis } from 'react-icons/ai';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import GetMessagesSWR from '../../data/messages/messagesSWR';
import { Keyboard } from '@capacitor/keyboard';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import MessageProfileModal from '../user/MessageProfileModal';
import blockUser from '../../data/safety/block';
import getMessages from '../../data/messages/getMessages';
import { processMessages } from '../utils/FormatMessageTimes';
import sendMessage from '../../data/messages/send';
import unmatch from '../../data/swipe/unmatch';
import useSWR from 'swr';
import { BASE_URL } from '../../globals';
const MessagingModal = ({
	isOpen,
	userObj,
	SetStatus,
	Conversation,
	UnmatchFromMMSplice,
	pageRef,
	setShowFooter,
	UpdateMessages,
}) => {
	const messagesEndRef = useRef(null);
	const message = useRef(null);
	const modal = useRef();

	const [isProfileModalActive, setIsProfileModalActive] = useState(false);
	const [messages, setMessages] = useState(null);
	const [isFirstPass, setIsFirstPass] = useState(true);
	const [tempObj, setTempObj] = useState([]);
	const [isKeyboardActive, setKeyboardStatus] = useState(false);
	const [keyboardHeight, setKeyboardHeight] = useState(0);
	const [convoId, setConvoId] = useState('');
	const [mostRecentMessage, setMostRecentMessage] = useState(null);
	const fetcher = (...args) =>
		fetch(...args, {
			headers: {
				authorization: localStorage.getItem('auth'),
				'content-type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({
				userToMessage: Conversation?.match_uuid,
				convoId: Conversation?.conversation_id,
			}),
		}).then((res) =>
			res.json().then((data) => {
				return data;
			})
		);

	const { data: messageSWRData } = useSWR(
		isOpen ? `${BASE_URL}/api/messages/get` : null,
		fetcher,
		{
			refreshInterval: 5000,
		}
	);
	// let messageSWRData = GetMessagesSWR(
	// 	Conversation?.match_uuid,
	// 	Conversation?.conversation_id,
	// 	isOpen
	// );

	useEffect(() => {
		if (!isFirstPass && Conversation) {
			// Otherwise messages get out of order???
			const toSet = messageSWRData?.data?.sort(function (a, b) {
				return a.id - b.id;
			});
			setMessages(toSet);
		}
	}, [messageSWRData]);

	const performMessageFetch = async (match_uuid, conversation_id) => {
		if (conversation_id) {
			console.log('Performing message fetch.');
			let data;
			if (conversation_id) {
				data = await getMessages(match_uuid, conversation_id);
			} else {
				console.log('Error! Invalid Convo id. ');
			}

			let bobj = data?.responseData?.data?.sort(function (a, b) {
				return parseInt(a.id) - parseInt(b.id);
			});

			let df = await findLastIndex(
				bobj,
				'recieving_user',
				Conversation?.match_uuid
			);

			setMostRecentMessage(bobj[df]?.id);

			setMessages(bobj);
		}
	};

	useEffect(() => {
		setMessages(null);
		setConvoId(Conversation?.conversation_id);
		performMessageFetch(
			Conversation?.match_uuid,
			Conversation?.conversation_id
		);
		setIsFirstPass(false);
	}, [isOpen]);

	function findLastIndex(array, searchKey, searchValue) {
		var index = array
			?.slice()
			.reverse()
			.findIndex((x) => x[searchKey] === searchValue);
		var count = array?.length - 1;
		var finalIndex = index >= 0 ? count - index : index;

		return finalIndex;
	}
	const setUpKeyboard = async () => {
		await Keyboard.setResizeMode({ mode: 'none' });
		window.addEventListener('keyboardWillShow', async (info) => {
			setKeyboardStatus(true);
			setKeyboardHeight(info.keyboardHeight);
			setTimeout(() => {
				scrollToBottom();
			}, [235]);
		});
		window.addEventListener('keyboardWillHide', async () => {
			setKeyboardStatus(false);
			setKeyboardHeight(0);
		});
	};
	useEffect(() => {
		setKeyboardHeight(0);
		setKeyboardStatus(false);
		Keyboard.removeAllListeners().then(() => {
			setUpKeyboard();
		});
	}, []);

	const appendMessage = (body) => {
		if (body !== '') {
			let objN = messages;
			let newMessage = {};
			newMessage.id = 999999999999999;
			newMessage.created_at = +new Date();
			newMessage.recieving_user = Conversation.match_uuid;
			newMessage.sending_user = '';
			newMessage.messages = body;
			newMessage.convoid = Conversation?.conversation_id;
			objN?.push(newMessage);

			const tp = objN?.sort(function (a, b) {
				return a.id - b.id;
			});

			setTempObj(tp);
			sendMessage(Conversation?.match_uuid, body).then((data) => {
				setMostRecentMessage(data?.responseData[0]?.id);
			});
			return tp;
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	useEffect(() => {
		const sortedArr = tempObj?.sort(function (a, b) {
			return a.id - b.id;
		});
		setMessages(sortedArr);
	}, [tempObj]);

	const scrollToBottom = () => {
		messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const [isPopoverActive, setPopoverStatus] = useState(false);

	return (
		<IonModal
			presentingElement={pageRef.current}
			isOpen={isOpen}
			ref={modal}>
			{/* <div className="bg-opacity-0 flex flex-col h-full none"> */}
			<IonHeader color="light" className="ion-no-border ">
				<IonToolbar
					color="light"
					className="py-1 rounded-tr-3xl border-b border-t-gray-50 border-t-2 border-b-gray-100 rounded-tl-3xl ">
					<IonButtons slot="start">
						<div className="flex items-center space-x-2">
							<MdOutlineArrowBackIos
								onClick={() => {
									setMessages(null);
									Keyboard.removeAllListeners();
									setMessages(null);

									setMessages(null);
									setShowFooter(true);
									SetStatus(false);
								}}
								className="messaging-back-arrow"
							/>
							<div
								className="flex items-center space-x-2"
								onClick={() => {
									setIsProfileModalActive(true);
								}}>
								<img
									className="h-10 w-10 rounded-full object-cover"
									src={`https://wave-profile.s3.us-west-1.amazonaws.com/${Conversation?.match_uuid}profile_image${Conversation?.profile_images[0]}`}></img>
								<div className="font-semibold text-xl">
									{/* TODO: Add age */}
									{Conversation?.first_name}
									{Conversation?.birthday
										? `, ${Conversation?.birthday}`
										: ''}
								</div>
							</div>
						</div>
					</IonButtons>
					<IonButtons slot="end">
						<AiOutlineEllipsis
							onClick={() => {
								setPopoverStatus(true);
							}}
							id="mt-btt"
							className="messaging-ellipsis"
						/>
						<IonPopover
							isOpen={isPopoverActive}
							trigger="mt-btt"
							dismissOnSelect={true}
							onDidDismiss={() => {
								setPopoverStatus(false);
							}}>
							<IonContent>
								<IonList>
									<IonItem
										button={true}
										onClick={async () => {
											await unmatch(
												Conversation?.match_uuid
											);
											setMessages(null);
											setShowFooter(true);
											setMessages(null);

											await UnmatchFromMMSplice(
												Conversation?.match_uuid
											);
											Keyboard.removeAllListeners();

											SetStatus(false);

											setPopoverStatus(false);
										}}
										detail={false}>
										<IonLabel>Unmatch</IonLabel>
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={async () => {
											await unmatch(
												Conversation?.match_uuid
											);
											await blockUser(
												Conversation?.match_uuid
											);
											setMessages(null);
											setShowFooter(true);
											setMessages(null);

											await UnmatchFromMMSplice(
												Conversation?.match_uuid
											);
											Keyboard.removeAllListeners();
											SetStatus(false);
											setPopoverStatus(false);
										}}>
										<IonLabel>Block</IonLabel>
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={async () => {
											await unmatch(
												Conversation?.match_uuid
											);
											await blockUser(
												Conversation?.match_uuid
											);
											setMessages(null);
											setShowFooter(true);
											setMessages(null);

											await UnmatchFromMMSplice(
												Conversation?.match_uuid
											);
											SetStatus(false);

											setPopoverStatus(false);
										}}>
										<IonLabel>Report</IonLabel>
									</IonItem>
									<IonItem
										onClick={() => {
											setPopoverStatus(false);
										}}
										button={true}
										detail={false}
										lines="none">
										<IonLabel>Close</IonLabel>
									</IonItem>
								</IonList>
							</IonContent>
						</IonPopover>
					</IonButtons>
					<IonTitle>
						{isProfileModalActive ? (
							<MessageProfileModal
								isOpen={isProfileModalActive}
								SetStatus={setIsProfileModalActive}
								User={Conversation}
							/>
						) : null}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent mode={'ios'} scrollY={true} className="">
				<div
					className={`h-full overflow-y-scroll transition-all ease-in-out duration-[255ms] bg-white-ttt p-2 space-y-2 `}>
					{processMessages(messages)?.map((message, idx) => {
						return convoId === message?.convoid ? (
							<div key={idx}>
								{message?.time_string && (
									<div
										className="w-full mx-auto text-center"
										key={idx}>
										<div className="text-gray-500 text-xs">
											{message?.time_string}
										</div>
									</div>
								)}
								<div className="">
									{message.recieving_user ===
									Conversation?.match_uuid ? (
										<>
											<div className="message-body mb-sent text-left">
												<div className="messaging-chat-tail mct-sent "></div>
												<span className="message-body-inner ">
													{message.messages}
												</span>
											</div>
											{mostRecentMessage &&
											message?.id ===
												mostRecentMessage ? (
												<div className="w-full flex mt-1">
													<span className="ml-auto text-gray-400 italic text-xs pr-1 ">
														Delivered
													</span>
												</div>
											) : null}
										</>
									) : (
										<div className="message-body mb-received">
											<div className="messaging-chat-tail mct-rec"></div>
											<span className="message-body-inner">
												{message.messages}
											</span>
										</div>
									)}
								</div>
							</div>
						) : null;
					})}
					<div ref={messagesEndRef} />
				</div>
			</IonContent>

			<IonFooter
				onClick={() => {
					// scrollToBottom();
				}}
				mode={'ios'}
				className={`transition-all ease-in-out duration-[265ms] bg-white z-[999999]`}
				style={{
					paddingBottom: isKeyboardActive ? keyboardHeight - 20 : 0,
				}}>
				<IonToolbar
					className={`flex text-black `}
					color="light"
					mode="ios">
					<IonTextarea
						autoGrow={true}
						rows={1}
						ref={message}
						className="border-gray-200 border rounded-2xl w-7/8 px-2 py-[.9px] mx-auto z-40 pr-12 text-black light:text-black dark:text-black"
						type="text"
						placeholder="Message"
						mode={'ios'}
						inputmode={'text'}
						autoCapitalize={true}
						autoCorrect={true}
						spellcheck={true}
					/>
					<IonButton
						className="absolute right-2 rounded-full  -bottom-0 z-[50] flex rounded-full shadow-none bg-opacity-0"
						color="clear"
						onClick={() => {
							appendMessage(message?.current?.value);
							message.current.value = '';
						}}>
						<BsFillArrowUpCircleFill className="z-50 text-wave-blue hover:text-blue-900 focus:text-blue-900 click:text-blue-900 rounded-full text-xl bg-opacity-0 mx-auto my-auto " />
					</IonButton>
				</IonToolbar>
			</IonFooter>
			{/* </div> */}
		</IonModal>
	);
};

export default MessagingModal;
