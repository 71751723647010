import { BASE_URL } from '../../globals';
import fetch from 'unfetch';
/**
 * 
 * @param {*} user 
 * 
 * password: user.password,
			firstName: user.firstName,
			lastName: user.lastName,
			phoneNumber: user.phoneNumber,
			username: user.username,
			gender: user.gender,
			isHost: user.isHost,
 * 
 * 
 * @returns 
 */
const SubmitSignup = async (user) => {
	const url = `${BASE_URL}/api/signup/submit`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			password: user.password,
			firstName: user.firstName,
			bio: user.bio,
			lastName: user.lastName,
			birthday: user.birthday,
			gender: user.gender,
			interested_in: user.interested_in,
		}),
	});

	if (res.ok) {
		localStorage.clear();
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		localStorage.setItem('userData', JSON.stringify(await res.json()));
		localStorage.setItem('auth', responseData.jwt);
		return { status: 0, data: responseData?.data };
	} else {
		let rd = await res.json();

		return { status: 1, data: rd };
	}
};

export default SubmitSignup;
