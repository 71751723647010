import {
	BsGenderAmbiguous,
	BsGenderFemale,
	BsGenderMale,
} from 'react-icons/bs';

import { useState } from 'react';

const GenderSelect = ({
	setDidPerform,
	actionToPerform,
	initialVal,
	refreshProf,
	setIsSaving,
}) => {
	const [selected, setSelected] = useState(initialVal);

	let notSelected =
		'w-1/3 h-48 mx-2 rounded-lg px-2 py-2 flex flex-col border-slate-900 border text-slate-900';
	let isSelected =
		' w-1/3 h-48 mx-2 rounded-lg px-2 py-2 flex flex-col border-slate-900 border bg-slate-900';
	let notSelectedText =
		'w-1/3 rounded-lg px-1 py-2 flex flex-col border-slate-900 border mx-2 text-slate-900';
	let isSelectedText =
		'w-1/3 rounded-lg px-1 py-2 border-slate-900 border bg-slate-900 mx-2 text-white';
	return (
		<>
			<div className="flex flex-row py-4 px-4 mt-6 w-full">
				<div
					className={selected === 0 ? isSelected : notSelected}
					onClick={async () => {
						setIsSaving(true);
						setSelected(0);
						setDidPerform(true);

						await actionToPerform(0);
						setIsSaving(false);
						await refreshProf();
					}}>
					<BsGenderFemale className="mx-auto my-auto text-6xl"></BsGenderFemale>
				</div>
				<div
					className={selected === 1 ? isSelected : notSelected}
					onClick={async () => {
						setIsSaving(true);
						setSelected(1);
						setDidPerform(true);

						await actionToPerform(1);
						setIsSaving(false);
						await refreshProf();
					}}>
					<BsGenderMale className="mx-auto my-auto text-6xl"></BsGenderMale>
				</div>
				<div
					className={selected === 2 ? isSelected : notSelected}
					onClick={async () => {
						setIsSaving(true);
						setSelected(2);
						setDidPerform(true);

						await actionToPerform(2);
						setIsSaving(false);
						await refreshProf();
					}}>
					<BsGenderAmbiguous className="mx-auto my-auto text-6xl"></BsGenderAmbiguous>
				</div>
			</div>
			<div className="flex flex-row py-2 px-4 text-center text-slate-900 font-semibold font-wavecondensed w-full">
				<div
					className={
						selected === 0 ? isSelectedText : notSelectedText
					}
					onClick={async () => {
						setIsSaving(true);
						setSelected(0);
						setDidPerform(true);
						await actionToPerform(0);
						setIsSaving(false);
					}}>
					Men
				</div>
				<div
					className={
						selected === 1 ? isSelectedText : notSelectedText
					}
					onClick={async () => {
						setIsSaving(true);
						setSelected(1);
						setDidPerform(true);
						await actionToPerform(1);
						setIsSaving(false);
					}}>
					Women
				</div>
				<div
					className={
						selected === 2 ? isSelectedText : notSelectedText
					}
					onClick={async () => {
						setIsSaving(true);
						setSelected(2);
						setDidPerform(true);
						await actionToPerform(2);
						setIsSaving(false);
					}}>
					Both
				</div>
			</div>
		</>
	);
};
export default GenderSelect;
