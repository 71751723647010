import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} NOPARAMSNECCESARY no params needed. Returns an array of the users data u have saved :) 
 
 * @returns status: error or status:success. If success, it will return data with a boolean (didMatch)!
 */
const getSaved = async () => {
	const url = `${BASE_URL}/api/profile/saved`;
	const res = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		return { status: 'success', data: responseData };
	} else {
		return { status: 'error' };
	}
};
export default getSaved;
