import '../ui/LoadingPageTwo.css';
import { useEffect, useRef, useState } from 'react';

const WaveLoadingAnimation = () => {
	const ballsRef = useRef([]);

	useEffect(() => {
		ballsRef.current.forEach((ball, index) => {
			const animationDelay = 100 * index;
			ball.style.animationDelay = `${animationDelay}ms`;
		});
	}, []);

	return (
		<div className="flex flex-col">
			<div className="progress mx-auto">
				{Array.from({ length: 15 }).map((_, index) => (
					<span
						key={index}
						ref={(el) => (ballsRef.current[index] = el)}></span>
				))}
			</div>
		</div>
	);
};

export default WaveLoadingAnimation;
