import { BASE_URL } from '../../globals';
import fetch from 'unfetch';

/**
 *
 * @param {*} newBio
 * @returns
 */
const upload = async (files, position) => {
	const url = `${BASE_URL}/api/profile/upload`;

	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			filePath: files,
			position: position,
		}),
	});

	if (res.ok) {
		let data = await res.json();
		return { status: 'success', id: data?.photo_id };
	} else {
		return { status: 'error' };
	}
};
export default upload;
