export const handleError = async (err) => {
	console.warn(err);
	return JSON.stringify({
		status: 400,
		statusCode: 400,
		code: 400,
		ok: false,
		message: 'Stupid network Error',
	});
};
