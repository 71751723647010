import { chevronBackCircleOutline, stop } from 'ionicons/icons';

import { IonActionSheet } from '@ionic/react';

const SafetyOptions = ({
	setShowActionSheet,
	showActionSheet,
	userToBlock,
	blockUser,
	doRefresh,
}) => {
	return (
		<IonActionSheet
			isOpen={showActionSheet}
			onDidDismiss={() => setShowActionSheet(false)}
			cssClass="my-custom-class"
			buttons={[
				{
					text: 'Block',
					role: 'destructive',
					icon: stop,
					id: 'block-button',
					data: {
						type: 'Block',
					},
					handler: () => {
						blockUser(userToBlock);
						doRefresh();
					},
				},
				{
					text: 'Report',
					role: 'destructive',
					icon: stop,
					id: 'report-button',
					data: {
						type: 'Block',
					},
					handler: () => {
						blockUser(userToBlock);
					},
				},

				{
					text: 'Cancel',
					icon: chevronBackCircleOutline,
					role: 'cancel',
					handler: () => {},
				},
			]}
		/>
	);
};

export default SafetyOptions;
