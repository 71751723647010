import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { handleError } from '../../components/utils/ErrorHandling';
import { BASE_URL } from '../../globals';

/**
 * @param {*} phoneNumber The users phone number
 * @param {*} password The users password
 * @returns status:"success" || status:"error"
 */
const loginSMS = async (phoneNumber) => {
	const url = `${BASE_URL}/api/login/smsconfirmation`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			'content-type': 'application/json',
		},
		body: JSON.stringify({
			phoneNumber: phoneNumber,
		}),
	}).catch(handleError);

	if (res.ok) {
		return { status: 'success' };
	} else {
		return { status: 'error' };
	}
};
export default loginSMS;
