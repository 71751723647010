import 'swiper/css';
import 'swiper/css/pagination';

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import {
	AiOutlineClose,
	AiOutlineLoading,
	AiOutlineWarning,
} from 'react-icons/ai';
import { FaHandHoldingHeart, FaSpinner } from 'react-icons/fa';
import { ImSpinner, ImSpinner10, ImSpinner4 } from 'react-icons/im';
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { IonContent, IonModal } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef, useState } from 'react';

import { CalculateAge } from '../utils/FormatData';
import { IoIosMore } from 'react-icons/io';
import { MdWavingHand } from 'react-icons/md';
import SafetyOptions from '../safety/blockActionSheet';
import getProfileImages from '../../data/profile/getProfileImages';
import like from '../../data/swipe/like';
import save from '../../data/swipe/save';
import updatebio from '../../data/profile/updatebio';

// import 'swiper/css/scrollbar';

/** THIS IS THE MAIN SWIPE NOW
 *
 * @param {*} param0
 * @returns
 */
const SwipePopUp = ({
	isOpen,
	doRefresh,
	SetStatus,
	User,
	showLikeOptions,
	setShowFooter,
	setEditProfileModalStatus,
	HandleLike,
	HandleSave,
	HandleDislike,
	BlockUser,
}) => {
	const [imageIndex, setImageIndex] = useState(0);
	const [imageArr, setImgArr] = useState([]);
	const [showActionSheet, setShowAction] = useState(false);
	const [likeLoading, setLikeLoading] = useState(false);
	const [dislikeLoading, setDislikeLoading] = useState(false);
	useEffect(() => {
		GetProfileImages();
	}, [isOpen]);

	const GetProfileImages = async () => {
		getProfileImages(true, User?.uuid).then((data) => {
			setImgArr(data?.images);
		});
	};

	useEffect(() => {
		if (isOpen) {
			setShowFooter(false);
		} else {
			setShowFooter(true);
		}
	}, [isOpen]);

	const HandleBlockUser = (userToBlock) => {
		BlockUser(userToBlock);
		doRefresh();
		setShowFooter(true);
		SetStatus(false);
	};

	return (
		<IonModal slot="fixed" className="" isOpen={isOpen} canDismiss={true}>
			<IonContent color="secondary" scrollY={false}>
				<div className="w-full h-t-safe mt-safe pb-safe flex flex-col justify-between bg-white pattern-polka-gray-500/20 pattern-polka-scale-[.25] rounded-t-3xl overflow-y-scroll">
					{imageArr === null || imageArr?.length === 0 ? (
						<AiOutlineClose
							onClick={() => {
								setShowFooter(true);
								SetStatus(false);
							}}
							className="absolute z-50 t-safe mt-2 right-2 text-3xl text-gray-500 bg-white border border-gray-500 rounded-full p-2 my-auto"
						/>
					) : null}
					<div className="p-1 pt-1.5 rounded-t-3xl pb-4">
						<Swiper
							modules={[Navigation, Pagination, Scrollbar, A11y]}
							spaceBetween={50}
							slidesPerView={1}
							pagination={{ clickable: true }}
							scrollbar={{ draggable: true }}
							onSwiper={(swiper) => console.log(swiper)}
							onSlideChange={() => console.log('slide change')}
							className="h-[calc(55vh)]">
							{imageArr?.map((key, idx) => {
								return (
									<SwiperSlide key={key}>
										<div className="absolute right-2 top-2 z-[9000000] flex gap-2">
											{showLikeOptions ? (
												<AiOutlineWarning
													onClick={() => {
														setShowAction(true);
													}}
													className="text-3xl text-white bg-yellow-400 rounded-full p-1.5 my-auto"
												/>
											) : null}
											<AiOutlineClose
												onClick={() => {
													setShowFooter(true);
													SetStatus(false);
												}}
												className="text-3xl text-gray-500 bg-white rounded-full p-2 my-auto"
											/>
										</div>
										{!showLikeOptions ? (
											<div className="absolute left-2 top-2 z-[9000000] flex gap-2">
												<IoIosMore
													onClick={() => {
														setEditProfileModalStatus(
															true
														);
													}}
													className="pattern-lines-diagonal-right-gray-100/70 pattern-lines-diagonal-right-scale-[0.6] text-5xl text-white rounded-full p-3  my-auto bg-gray-900 bg-opacity-50"
												/>
											</div>
										) : null}
										<img
											className="z-50 h-full w-full object-cover rounded-3xl shadow-black"
											src={
												imageArr[0] !== undefined
													? `https://wave-profile.s3.us-west-1.amazonaws.com/${User?.uuid}profile_image${imageArr[idx]}`
													: null
											}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null; // prevents looping
												if (
													imageIndex ===
													imageArr.length
												) {
													setImageIndex(0);
													currentTarget.src =
														imageArr[imageIndex];
												} else {
													setImageIndex(
														imageIndex + 1
													);
													currentTarget.src =
														imageArr[imageIndex];
												}
											}}
										/>
									</SwiperSlide>
								);
							})}
						</Swiper>
						<div className="w-full flex text-left text-lg font-light mt-3 z-20 px-3">
							<span className="font-semibold text-teal-900">
								{User?.first_name},
							</span>
							<span className="w-full ml-1 text-teal-900">
								{typeof User?.birthday === 'number'
									? User?.birthday
									: CalculateAge(User?.birthday)}
							</span>
						</div>

						<div className="text-teal-900 text-xs font-light my-2 px-3">
							BIO
						</div>
						<div className="px-3 overflow-y-scroll text-teal-900">
							{User?.bio || ''}
						</div>
					</div>

					{showLikeOptions ? (
						<div
							ref={(el) => {
								if (!el) return;
							}}
							className="space-x-2.5 mx-auto rounded-2xl w-full px-4 flex bg-transparent">
							<span className="flex mx-auto focus:bg-blue-200 w-1/6 pattern-lines-diagonal-right-gray-700/50 pattern-lines-diagonal-right-scale-[0.5] rounded-full bg-red-600 text-white">
								<AiOutlineClose
									className={`p-3 text-5xl w-full text-white mx-auto rounded-full ${
										dislikeLoading && 'hidden'
									}`}
									onClick={() => {
										setDislikeLoading(true);
										HandleDislike(User?.uuid).then(() => {
											setDislikeLoading(false);
										});
									}}
								/>
								<AiOutlineLoading
									className={`animate-spin mx-auto my-auto text-2xl ${
										!dislikeLoading && 'hidden'
									}`}
								/>
							</span>
							<FaHandHoldingHeart
								className="text-white rounded-full p-3 text-5xl w-1/2 bg-orange-400 pattern-lines-diagonal-right-gray-700/50 pattern-lines-diagonal-right-scale-[0.5]"
								onClick={() => {
									HandleSave(User?.uuid);
								}}></FaHandHoldingHeart>
							<span className=" mx-auto focus:bg-blue-200 w-1/2 pattern-lines-diagonal-right-gray-700/50 pattern-lines-diagonal-right-scale-[0.5] rounded-full p-3 bg-teal-600 text-white">
								<MdWavingHand
									className={`text-white mx-auto text-2xl ${
										likeLoading && 'hidden'
									}`}
									onClick={() => {
										setLikeLoading(true);
										HandleLike(User?.uuid).then((data) => {
											setLikeLoading(false);
										});
										// setLikeLoading(false);
									}}></MdWavingHand>
								<AiOutlineLoading
									className={`animate-spin mx-auto my-auto text-2xl ${
										!likeLoading && 'hidden'
									}`}
								/>
							</span>

							<SafetyOptions
								userToBlock={User?.uuid}
								setShowActionSheet={setShowAction}
								showActionSheet={showActionSheet}
								blockUser={HandleBlockUser}
								doRefresh={doRefresh}></SafetyOptions>
						</div>
					) : null}
				</div>
			</IonContent>
		</IonModal>
	);
};
export default SwipePopUp;
