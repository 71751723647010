import ActiveButton from '../../buttons/ActiveButton';
import LoadingButton from '../../buttons/LoadingButton';
import { useEffect } from 'react';

const SUPage6 = ({
	wrapperStyle,
	headerStyle,
	IsLoading,
	OnClick,
	AdditionalButtonStyle,
	IsActive,
	InterestedIn,
	SetState,
}) => {
	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>Show me</div>
				<ActiveButton
					AdditionalButtonStyle={AdditionalButtonStyle}
					Value={0}
					SetState={SetState}
					Message="Men"
					IsActive={InterestedIn === 0 ? true : false}
				/>
				<ActiveButton
					AdditionalButtonStyle={AdditionalButtonStyle}
					Value={1}
					SetState={SetState}
					Message="Women"
					IsActive={InterestedIn === 1 ? true : false}
				/>
				<ActiveButton
					AdditionalButtonStyle={AdditionalButtonStyle}
					Value={2}
					SetState={SetState}
					Message="Everyone"
					IsActive={InterestedIn === 2 ? true : false}
				/>
				<span className="mb-4"></span>
				<LoadingButton
					IsLoading={IsLoading}
					OnClick={OnClick}
					Message="Continue"
					IsSecondary={false}
					AdditionalButtonStyle={AdditionalButtonStyle}
				/>
			</>
		</div>
	);
};

export default SUPage6;
