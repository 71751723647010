export const FormatPhoneNumber = (phoneNumber) => {
	if (!phoneNumber) return phoneNumber;

	//Replace non-digit values
	phoneNumber = phoneNumber.replace(/[^\d]/g, '');

	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6
	)}-${phoneNumber.slice(6, 10)}`;
};

export const GetDayStringFromDate = (date) => {
	if (date) {
		var dateObject = new Date(date?.toString());
		const weekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		return weekNames[dateObject?.getDay()];
	} else {
		return 'error';
	}
};

export const GetDayIntFromDate = (date) => {
	var dateObject = new Date(date?.toString());
	return dateObject?.getDate();
};

export const GetMonthStringFromDate = (date) => {
	var dateObject = new Date(date?.toString());
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	return monthNames[dateObject?.getMonth()];
};

export const GetYearFromData = (date) => {
	var dateObject = new Date(date?.toString());
	return dateObject.getFullYear();
};

export const GetTimeStringFromTimeStamp = (date) => {
	var dateObject = new Date(date?.toString());
	var hours = dateObject?.getHours();
	var minutes = dateObject?.getMinutes();
	var ampm = hours >= 12 ? 'PM' : 'AM';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime = hours + ':' + minutes + ' ' + ampm;
	return strTime;
};

export const CheckIfSameDate = (date) => {
	var dateObject = new Date(date?.toString());
	var today = new Date();
	return (
		dateObject?.getDate() == today?.getDate() &&
		dateObject?.getMonth() == today.getMonth() &&
		dateObject?.getFullYear() == today?.getFullYear()
	);
};

export const GetFullTDStringFromDate = (startDate, endDate) => {
	return `${GetMonthStringFromDate(startDate)} ${GetDayIntFromDate(
		startDate
	)} ${GetTimeStringFromTimeStamp(startDate)}-${GetTimeStringFromTimeStamp(
		endDate
	)}`;
};

export const CalculateAge = (birthday) => {
	var birthdate = new Date(birthday);

	var cur = new Date();
	var diff = cur - birthdate; // This is the difference in milliseconds
	var age = Math.floor(diff / 31557600000);

	return age;
};
