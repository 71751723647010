import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @returns
 */
const getProfile = async () => {
	const url = `${BASE_URL}/api/profile/get/`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'text/plain',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		const data = await res.json();

		return data[0];
	} else {
		return { status: 'error' };
	}
};
export default getProfile;
