import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
} from 'react-grid-drag';

import SUPhotoUpload from './SUPhotoUpload';
import { useState } from 'react';

const SUPhotoGrid = ({ profileImages, setProfileImages }) => {
	const [items, setItems] = useState([null, null, null, null, null, null]);

	const HandleSetItems = (position, item) => {
		let tempProfileImages = [...profileImages];
		tempProfileImages[position] = item;

		setProfileImages(tempProfileImages);
	};

	const HandleDeleteItem = (position) => {
		let tempProfileImages = [...profileImages];
		tempProfileImages[position] = null;

		setProfileImages(tempProfileImages);
	};

	// supply your own state
	// target id will only be set if dragging from one dropzone to another.
	async function onChange(sourceIndex, targetIndex) {
		const nextState = swap(items, sourceIndex, targetIndex);

		const arrTwo = nextState.filter(function (el) {
			return el != null;
		});
		const updateProfOrder = [];
		for (let i in arrTwo) {
			updateProfOrder.push(arrTwo[i]);
		}

		for (let i = arrTwo.length; i < 6; i++) {
			arrTwo.push(null);
		}

		setItems(arrTwo);
	}

	return profileImages ? (
		<GridContextProvider onChange={onChange} style={{ zIndex: '5000' }}>
			<GridDropZone
				id=""
				boxesPerRow={3}
				cols={3}
				rowHeight={170}
				style={{
					gap: '10px',
					padding: '5px',
					height: '400px',
					width: '90%',
					margin: '0 auto',
					marginBottom: '-34px',
					touchAction: 'none',
				}}
				className="">
				{profileImages?.map((item, key) => {
					return (
						<GridItem
							key={item ? item + key : key}
							style={{
								width: '30%',
								height: '35%',
							}}>
							<SUPhotoUpload
								setItems={HandleSetItems}
								deleteItem={HandleDeleteItem}
								profilePhoto={item}
								position={key}
							/>
						</GridItem>
					);
				})}
			</GridDropZone>
		</GridContextProvider>
	) : (
		<div></div>
	);
};
export default SUPhotoGrid;
