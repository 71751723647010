import { ImSpinner8 } from 'react-icons/im';
import './LoadingPage.css';

import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
const LoadingPage = () => {
	return (
		<div className="w-screen h-screen absolute">
			<div className=" w-full h-full flex relative">
				<ImSpinner8 className="animate-spin text-wave-blue text-4xl my-auto mx-auto" />
			</div>
		</div>
	);
};

export default LoadingPage;
