import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} position
 * @returns
 */
const deleteFile = async (position) => {
	const url = `${BASE_URL}/api/profile/delete`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			position: position,
		}),
	});

	if (res.ok) {
		return { status: 'success' };
	} else {
		let err = await res.json();
		console.log(err);
		return { status: 'error' };
	}
};
export default deleteFile;
