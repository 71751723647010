import {
	IonButtons,
	IonContent,
	IonHeader,
	IonModal,
	IonText,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import {
	VerifyBio,
	VerifyFirstName,
	VerifyPhoneNumber,
} from '../utils/FormInputVerification';

import { AiOutlineClose } from 'react-icons/ai';
import { CalculateAge } from '../utils/FormatData';
import { IoIosArrowBack } from 'react-icons/io';
import SUPage1 from './SignUp/SUPage1';
import SUPage2 from './SignUp/SUPage2';
import SUPage3 from './SignUp/SUPage3';
import SUPage4 from './SignUp/SUPage4';
import SUPage5 from './SignUp/SUPage5';
import SUPage6 from './SignUp/SUPage6';
import SUPage7 from './SignUp/SUPage7';
import SUPage8 from './SignUp/SUPage8';
import SUPage9 from './Login/SUPage9';
import SUStatusIndicator from './SignUp/SUStatusIndicator';
import SubmitSignup from '../../data/signup/submitSignup';
import signupSMS from '../../data/signup/signupsms';
import upload from '../../data/profile/upload';
import { useHistory } from 'react-router';
import { useState } from 'react';
import verifyph from '../../data/signup/verifyPh';

const SignUpModal = ({ isOpen, onDidDismiss, setModalStatus }) => {
	const [errorMessage, setErrorMessage] = useState(null);
	const [suIndex, setSUIndex] = useState(0);

	const [pw, setPw] = useState(null);

	const [isLoading, setLoadingStatus] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [smsPin, setSMSPin] = useState(null);
	const [name, setName] = useState(null);
	const [birthday, setBirthday] = useState(null);
	const [gender, setGender] = useState(null);
	const [interestedIn, setInterestedIn] = useState(null);
	const [bio, setBio] = useState(null);
	const [profileImages, setProfileImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
	]);

	const history = useHistory();

	const HandleSignUp = async () => {
		if (suIndex === 0) {
			setLoadingStatus(true);
			let pnValid = VerifyPhoneNumber(phoneNumber);
			if (pnValid !== true) {
				setErrorMessage(pnValid);
				setLoadingStatus(false);
				return;
			}

			const didSendSMS = await signupSMS(phoneNumber);
			if (didSendSMS.status === 'error') {
				setLoadingStatus(false);
			} else {
				setSUIndex(suIndex + 1);

				setLoadingStatus(false);
			}

			return;
		}
		if (suIndex === 1) {
			setLoadingStatus(true);
			const didVerify = await verifyph(smsPin, phoneNumber, pw);
			if (didVerify?.status === 'error') {
				setErrorMessage('Unable to verify pin.');
			} else {
				setSUIndex(suIndex + 1);
			}
			setLoadingStatus(false);
			return;
		}
		if (suIndex === 2) {
			setLoadingStatus(true);
			let nameValid = VerifyFirstName(name);

			if (nameValid !== true) {
				setErrorMessage(nameValid);
				setLoadingStatus(false);
				return;
			}

			setSUIndex(suIndex + 1);

			setLoadingStatus(false);

			return;
		}
		if (suIndex === 3) {
			if (!birthday) {
				setErrorMessage(
					'Issue setting birthday — please select again.'
				);
			} else {
				let is18 = validateDOB(birthday);

				if (is18) {
					setSUIndex(suIndex + 1);
				}
			}
		}
		if (suIndex === 4) {
			setLoadingStatus(true);
			if (gender === null || gender === undefined) {
				setErrorMessage('Error, please select a gender.');
				setLoadingStatus(false);
				return;
			}
			setLoadingStatus(false);
			setSUIndex(suIndex + 1);
		}
		if (suIndex === 5) {
			setLoadingStatus(true);
			if (interestedIn === null || interestedIn === undefined) {
				setErrorMessage('Error, please select who you want to see.');
				setLoadingStatus(false);
				return;
			}
			setLoadingStatus(false);
			setSUIndex(suIndex + 1);
		}
		if (suIndex === 6) {
			setLoadingStatus(true);

			let bioValid = VerifyBio(bio);

			if (bioValid !== true) {
				setErrorMessage(bioValid);
				setLoadingStatus(false);
				return;
			}

			setSUIndex(suIndex + 1);
			setLoadingStatus(false);

			// return;
		}

		if (suIndex === 7) {
			setLoadingStatus(true);
			if (
				profileImages === null ||
				(Array.isArray(profileImages) &&
					profileImages.every((item) => item === null))
			) {
				setErrorMessage('Please add at least one photo');
			} else {
				setSUIndex(() => {
					return suIndex + 1;
				});
			}
			setLoadingStatus(false);
		}

		if (suIndex === 8) {
			setLoadingStatus(true);
			await CompleteSignUp();
		}
	};

	const CompleteSignUp = async () => {
		setLoadingStatus(true);
		if (phoneNumber) {
			const user = {
				password: 'password',
				firstName: name,
				gender: gender,
				birthday: birthday,
				interested_in: interestedIn,
				bio: bio,
			};

			const signUpUser = await SubmitSignup(user);
			if (signUpUser.status === 0) {
				// setSUIndex(7);
				let tempProfileImages = profileImages.filter(
					(item) => item !== null
				);
				(async () => {
					try {
						await Promise.race([
							Promise.all(
								tempProfileImages.map(
									async (item, index) =>
										await upload(item, index)
								)
							),
							new Promise((_, reject) =>
								setTimeout(
									() => reject(new Error('Timeout')),
									300000
								)
							),
						]);

						window.location = '/home';
					} catch (error) {
						console.log('ERROR', error);
						window.location = '/home';
						setModalStatus(false);
					}
				})();
			} else {
				setErrorMessage(signUpUser?.data?.data);
			}
		} else {
			setErrorMessage(
				'There was an error with signup, please try again later'
			);
			setLoadingStatus(false);
		}

		return;
	};

	function validateDOB(bday) {
		let age = CalculateAge(bday.toString());
		if (!age || age < 18) {
			setErrorMessage('You must be over 18 to register');
			return false;
		}
		return true;
	}

	const HandleBackButtonClick = () => {
		setSUIndex(suIndex - 1);
	};

	// Duplicated in Login.js
	let wrapperStyle = 'flex flex-col w-full py-10';
	let headerStyle =
		'text-center text-4xl font-light w-2/3 mx-auto text-gray-400 text-opacity-90 mb-10';
	let additionalInputWrapperStyle = 'w-3/4 mt-4 text-gray-200';
	let additionalButtonStyle = 'mt-16 w-3/4';
	let additionalActiveButtonStyle = 'mt-4 w-3/4';

	return (
		<IonModal
			fullscreen={true}
			isOpen={isOpen}
			showBackdrop={false}
			onDidDismiss={onDidDismiss}
			color={'secondary'}>
			<IonHeader color={'secondary'} className="ion-no-border">
				<IonToolbar color={'secondary'}>
					<IonButtons slot="end">
						<IonText color="primary" onClick={onDidDismiss}>
							<AiOutlineClose className="text-wave-inactive text-2xl mr-2" />
						</IonText>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent
				scrollY={false}
				className="h-screen"
				color={'secondary'}>
				<div className="relative bg-opacity-0 h-screen">
					<div className="h-1/4 relative">
						{suIndex > 2 ? (
							<IoIosArrowBack
								onClick={() => {
									HandleBackButtonClick();
								}}
								className="absolute top-4 left-4 text-3xl text-gray-400 font-thin"
							/>
						) : null}
						<SUStatusIndicator suIndex={suIndex} />
						<IonToast
							isOpen={errorMessage !== null}
							message={errorMessage}
							duration={2000}
							onWillDismiss={() => {
								setErrorMessage(null);
							}}
							color="primary"
							position="top"
						/>
						<div className="modal-wrapper text-gray-200">
							{suIndex === 0 ? (
								<SUPage1
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									AdditionalInputWrapperStyle={
										additionalInputWrapperStyle
									}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalButtonStyle
									}
									SetState={setPhoneNumber}
									IsLoading={isLoading}
								/>
							) : null}

							{suIndex === 1 ? (
								<SUPage2
									IsLoading={isLoading}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalButtonStyle
									}
									SetState={setSMSPin}
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
								/>
							) : null}

							{suIndex === 2 ? (
								<SUPage3
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									AdditionalInputWrapperStyle={
										additionalInputWrapperStyle
									}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalButtonStyle
									}
									SetState={setName}
									IsLoading={isLoading}
									InputValue={name}
								/>
							) : null}
							{suIndex === 3 ? (
								<SUPage4
									IsLoading={isLoading}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalButtonStyle
									}
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									SetState={setBirthday}
									Value={birthday}
								/>
							) : null}
							{suIndex === 4 ? (
								<SUPage5
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									IsLoading={isLoading}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalActiveButtonStyle
									}
									Gender={gender}
									SetState={setGender}
								/>
							) : null}
							{suIndex === 5 ? (
								<SUPage6
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									IsLoading={isLoading}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalActiveButtonStyle
									}
									InterestedIn={interestedIn}
									SetState={setInterestedIn}
								/>
							) : null}
							{suIndex === 6 ? (
								<SUPage8
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									AdditionalInputWrapperStyle={
										additionalInputWrapperStyle
									}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalButtonStyle
									}
									SetState={setBio}
									IsLoading={isLoading}
									InputValue={bio}
								/>
							) : null}
							{suIndex === 7 ? (
								<SUPage7
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									IsLoading={isLoading}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalActiveButtonStyle
									}
									profileImages={profileImages}
									setProfileImages={setProfileImages}
								/>
							) : null}
							{suIndex === 8 ? (
								<SUPage9
									wrapperStyle={wrapperStyle}
									headerStyle={headerStyle}
									IsLoading={isLoading}
									OnClick={HandleSignUp}
									AdditionalButtonStyle={
										additionalActiveButtonStyle
									}
									setModalStatus={setModalStatus}
								/>
							) : null}
						</div>
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};
export default SignUpModal;
