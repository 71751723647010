import { IonContent, IonModal } from '@ionic/react';
import { useRef, useState } from 'react';

import { BiArrowBack } from 'react-icons/bi';
import GenderSelect from './user/GenderSelect';
import LoadingButton from './buttons/LoadingButton';
import deleteProfile from '../data/profile/deleteProfile';
import getProfile from '../data/profile/get';
import updateInterestedIn from '../data/profile/updateInterestedIn';
import updateVisibility from '../data/safety/updateVisibility';
import { ImSpinner10, ImSpinner8 } from 'react-icons/im';

const SettingsModal = ({
	isOpen,
	SetStatus,
	userProf,
	setDidPerform,
	setUserProf,
	showWarning,
	setShowWarning,
}) => {
	const modal = useRef();
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleteLoading, setDeleteLoadingStatus] = useState(false);
	const updateWarningStatus = async (status) => {
		setShowWarning(status);
	};
	const refreshProf = async () => {
		const profRefresh = await getProfile();

		setUserProf(profRefresh);

		if (profRefresh?.isVisible) {
			await updateWarningStatus(false);
		} else {
			await updateWarningStatus(true);
		}
	};

	return (
		<IonModal
			ref={modal}
			isOpen={isOpen}
			onDidDismiss={() => {
				modal.current.dismiss().then(() => {
					SetStatus(false);
				});
			}}>
			<IonContent color={'secondary'} scrollY={false}>
				{!isSaving && (
					<BiArrowBack
						className={` ${
							isSaving && 'bg-black'
						} back-arrow mt-safe z-[9999]`}
						onClick={() => {
							if (!isSaving) {
								modal.current.dismiss().then(() => {
									SetStatus(false);
								});
							}
						}}
					/>
				)}
				{isSaving && (
					<ImSpinner8
						className={` 
					animate-spin bg-opacity-0 bg-gray-100 text-wave-blue back-arrow mt-safe z-[9999]`}
						onClick={() => {
							if (!isSaving) {
								modal.current.dismiss().then(() => {
									SetStatus(false);
								});
							}
						}}
					/>
				)}
				<div className="relative w-full flex flex-col mt-safe items-center h-full bg-white pattern-polka-gray-500/20 pattern-polka-scale-[.25] rounded-t-3xl">
					{deletePopUp && (
						<div
							className="absolute w-screen h-screen flex flex-col bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm"
							onClick={() => {
								setDeletePopUp(false);
							}}>
							<div className="mx-auto my-auto flex bg-white w-4/5 h-1/3 flex-col rounded-md border-gray-500 border relative shadow-xl px-2 py-4">
								<div className="text-center text-red-500 font-bold">
									Warning!
								</div>
								<div className="text-center text-red-700">
									Clicking below will PERMANENTLY delete your
									Wave account. All your matches, waves,
									saves, conversations will be gone forever.
									As a privacy mechanism, we scrub our
									database of all your data.
								</div>
								<div className="flex flex-col my-auto space-y-2 pt-6">
									<button
										className="w-3/4 border-2 border border-gray-400 bg-gray-600  rounded-md text-white py-2 px-3 mx-auto my-auto"
										onClick={async () => {
											setDeletePopUp(false);
										}}>
										Back To Safety Please.
									</button>
									<button
										className={
											'w-3/4 border-2 border border-red-400 bg-red-500 rounded-md text-white py-2 px-3 mx-auto my-auto'
										}
										onClick={async () => {
											setDeleteLoadingStatus(true);
											await deleteProfile();
											localStorage.clear();
											window.location = '/welcome';
											setDeleteLoadingStatus(false);
										}}>
										I'm Sure, Delete Account
									</button>
								</div>
							</div>
						</div>
					)}
					<div className="font-bold w-full mt-2 text-center mx-auto text-slate-800 font-wavecondensed text-4xl">
						SETTINGS
					</div>

					<div className="flex flex-row w-full mx-auto px-6 mt-6">
						<span className="w-1/2 my-auto text-left text-slate-800 font-semibold font-wavecondensed">
							Let People See Me
						</span>
						<div className="bg-slate-900 w-1/2 my-auto rounded-lg py-1 px-1">
							<button
								onClick={async () => {
									setIsSaving(true);
									setShowWarning(false);
									await updateVisibility(true);
									await refreshProf();
									setIsSaving(false);
								}}
								className={
									!showWarning
										? 'mx-auto bg-teal-500 h-full w-1/2 rounded-lg py-1 my-auto px-3'
										: 'mx-auto h-full w-1/2 rounded-lg py-1 my-auto px-3'
								}>
								Yes
							</button>
							<button
								onClick={async () => {
									setIsSaving(true);
									setShowWarning(true);
									await updateVisibility(false);
									await refreshProf();
									setIsSaving(false);
								}}
								className={
									showWarning
										? 'mx-auto bg-orange-500 h-full w-1/2 rounded-lg py-1 my-auto px-3'
										: 'mx-auto h-full w-1/2 rounded-lg py-1 my-auto px-3'
								}>
								No
							</button>
						</div>
					</div>

					<GenderSelect
						setIsSaving={setIsSaving}
						setUserProf={setUserProf}
						setDidPerform={setDidPerform}
						refreshProf={refreshProf}
						actionToPerform={updateInterestedIn}
						initialVal={userProf?.interested_in}></GenderSelect>
					<div className="flex flex-col mt-24 z-50">
						{!deletePopUp && (
							<LoadingButton
								AdditionalButtonStyle={
									'w-full border-2 border border-red-400 bg-red-400 rounded-md text-white py-2 px-3'
								}
								Message={'Delete Account'}
								IsLoading={isDeleteLoading}
								OnClick={async () => {
									setDeletePopUp(true);
								}}></LoadingButton>
						)}
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};

export default SettingsModal;
