import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

const getLikedBy = async () => {
	const url = `${BASE_URL}/api/profile/getLikes/`;
	const res = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		let responseData = await res.json();

		return { status: true, data: responseData.data };
	} else {
		let responseData = await res.json();

		return { status: false };
	}
};

export default getLikedBy;
