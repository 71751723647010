import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} userToUnmatch
 * @returns
 */
const unmatch = async (userToUnmatch) => {
	const url = `${BASE_URL}/api/swipe/unmatch`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			userToUnmatch: userToUnmatch,
		}),
	});

	if (res.ok) {
		// let responseData = JSON.parse(JSON.stringify(await res.json()));
		return { status: 'success' };
	} else {
		return { status: 'error' };
	}
};
export default unmatch;
