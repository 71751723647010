import LoadingButton from '../../buttons/LoadingButton';
import ReactCodeInput from 'react-code-input';

const SUPage2 = ({
	IsLoading,
	OnClick,
	AdditionalButtonStyle,
	SetState,
	wrapperStyle,
	headerStyle,
}) => {
	const HandleCodeChange = (code) => {
		SetState(code);
	};

	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>Enter Verification Code</div>
				<ReactCodeInput
					type={'number'}
					inputMode="numeric"
					onChange={HandleCodeChange}
					fields={4}
					className="mx-auto font-arial space-x-4"
					inputStyle={{
						color: 'gray',
						textAlign: 'center',
						borderBottomWidth: '1px',
						borderRadius: '0px',
						padding: '10px',
						paddingBottom: '0px',
						outline: 'none',
						borderBottomColor: 'gray',
						background: 'transparent',
						height: '7vh',
					}}
				/>
			</>
			<LoadingButton
				IsLoading={IsLoading}
				OnClick={OnClick}
				Message="Continue"
				IsSecondary={false}
				AdditionalButtonStyle={AdditionalButtonStyle}
			/>
		</div>
	);
};

export default SUPage2;
