import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} userToMessage
 * @param {*} convoId
 * @returns
 */

const updateInRange = async (isInRange) => {
	const url = `${BASE_URL}/api/location/updateInRange`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			isInRange,
		}),
	});

	if (res.ok) {
		let responseData = await res.json();

		return { status: 'success' };
	} else {
		let responseData = await res.json();

		return { status: 'error' };
	}
};
export default updateInRange;
