import ActiveButton from '../../buttons/ActiveButton';
import LoadingButton from '../../buttons/LoadingButton';

const SUPage5 = ({
	wrapperStyle,
	headerStyle,
	IsLoading,
	OnClick,
	AdditionalButtonStyle,
	IsActive,
	Gender,
	SetState,
}) => {
	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>I am a</div>
				<ActiveButton
					AdditionalButtonStyle={AdditionalButtonStyle}
					Value={0}
					SetState={SetState}
					Message="Man"
					IsActive={Gender === 0 ? true : false}
				/>
				<ActiveButton
					AdditionalButtonStyle={AdditionalButtonStyle}
					Value={1}
					SetState={SetState}
					Message="Woman"
					IsActive={Gender === 1 ? true : false}
				/>
				<ActiveButton
					AdditionalButtonStyle={AdditionalButtonStyle}
					Value={2}
					SetState={SetState}
					Message="Other"
					IsActive={Gender === 2 ? true : false}
				/>
				<span className="mb-4"></span>
				<LoadingButton
					IsLoading={IsLoading}
					OnClick={OnClick}
					Message="Continue"
					IsSecondary={false}
					AdditionalButtonStyle={AdditionalButtonStyle}
				/>
			</>
		</div>
	);
};

export default SUPage5;
