import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

const updateOrder = async (imgArr) => {
	const url = `${BASE_URL}/api/profile/updateOrder`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			imgArr: imgArr,
		}),
	});

	if (res.ok) {
		const data = await res.json();

		return { status: 'success', images: data[0]?.profile_images };
	} else {
		return { status: 'error' };
	}
};
export default updateOrder;
