import { IonFooter, IonRow } from '@ionic/react';

import { BiChat } from 'react-icons/bi';
import { IoMdPerson } from 'react-icons/io';
import { MdSurfing } from 'react-icons/md';
import { TbSunset2 } from 'react-icons/tb';
import { useEffect } from 'react';

export function Footer({ currPage, setCurrPage, UserImage, User }) {
	return (
		<IonFooter
			className={`relative bg-none
				 border-opacity-0 z-50`}
			color="light">
			<div className="absolute b-safe flex w-full">
				<IonRow
					className={`h-[7vh] border-b-4 mx-auto w-11/12 ${
						(currPage === 0 && 'border-orange-400') ||
						(currPage === 1 && 'border-blue-500') ||
						(currPage === 3 && 'border-red-500') ||
						(currPage === 2 && 'border-teal-500')
					} bg-white rounded-full text-gray-800 shadow-sm `}>
					{UserImage !== null ? (
						<div
							onClick={() => {
								setCurrPage(0);
							}}
							className={
								currPage !== 0
									? `text-2xl w-1/4 my-auto text-gray-600`
									: `text-2xl w-1/4 my-auto text-orange-400`
							}>
							<img
								src={`https://wave-profile.s3.us-west-1.amazonaws.com/${User?.uuid}profile_image${UserImage}`}
								alt="Account Profile"
								className={`border w-7 h-7 rounded-full object-cover mx-auto ${
									currPage === 0
										? 'border-orange-400'
										: 'border-gray-600'
								}`}
							/>
						</div>
					) : (
						<IoMdPerson
							onClick={() => {
								setCurrPage(0);
							}}
							className={
								currPage !== 0
									? `text-2xl w-1/4 my-auto text-gray-600`
									: `text-2xl w-1/4 my-auto text-orange-400`
							}
						/>
					)}

					<TbSunset2
						onClick={() => {
							setCurrPage(3);
						}}
						className={
							currPage !== 3
								? `text-2xl w-1/4 my-auto text-gray-600`
								: `text-2xl w-1/4 my-auto text-red-500`
						}
					/>

					<MdSurfing
						className={
							currPage !== 1
								? `text-2xl w-1/4 my-auto text-gray-600`
								: `text-2xl w-1/4 my-auto text-blue-500`
						}
						onClick={() => {
							setCurrPage(1);
						}}
					/>

					<BiChat
						className={
							currPage !== 2
								? `text-2xl w-1/4 my-auto text-gray-600`
								: `text-2xl w-1/4 my-auto text-teal-500`
						}
						onClick={() => {
							setCurrPage(2);
						}}
					/>
				</IonRow>
			</div>
			<div className="h-[2vh] w-full"></div>
		</IonFooter>
	);
}
