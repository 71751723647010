/* eslint-disable jsx-a11y/alt-text */
import './SwipeCard.css';

import { AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import { useEffect, useRef, useState } from 'react';

import { CalculateAge } from '../utils/FormatData';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { ImSpinner8 } from 'react-icons/im';
import { IonCard } from '@ionic/react';
import { MdWavingHand } from 'react-icons/md';
import { createGesture } from '@ionic/core';
import errorImage from '../images/error_image.png';

const SwipeCard = ({
	name,
	uuid,
	HandleSave,
	profile_image,
	SetProfileModal,
	setActiveUser,
	u,
	birthday,
	setShowActionSheet,
	setUserToBlock,
	HandleLike,
	HandleDislike,
	doRefresh,
	setIsSaveToast,
	ix,
}) => {
	const cardRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (cardRef.current) {
			gestureInit();
		}
	}, [cardRef]);

	useEffect(() => {
		setIsLoading(false);
	}, []);

	const gestureInit = async () => {
		const card = cardRef.current;
		if (card) {
			const swipeGesture = createGesture({
				el: cardRef.current,
				gestureName: 'card-swipe',
				onMove: (detail) => {
					cardRef.current.style.transform = `translateX(${
						detail.deltaX
					}px) rotate(${detail.deltaX / 20}deg)`;
				},
				onEnd: async (detail) => {
					const windowWidth = window.innerWidth;
					card.style.transition =
						'0.25s cubic-bezier(.175, .885, .32, 1.275)';
					if (detail.deltaX > windowWidth / 2) {
						card.style.transform = `translateX(${
							windowWidth * 1.5
						}px)`;
						await HandleLike(ix);
						doRefresh();
					} else if (detail.deltaX < -windowWidth / 2) {
						card.style.transform = `translateX(${
							-windowWidth * 1.5
						}px)`;
						await HandleDislike(ix);
						doRefresh();
					} else {
						card.style.transform = '';
					}
				},
			});
			swipeGesture.enable();
		}
	};

	return (
		<div
			ref={cardRef}
			className="flex flex-col w-full h-full mt-8 bg-white rounded-3xl bg-dotted-spacing-[3px] bg-dotted-gray-50">
			<IonCard className="w-full px-2 h-96 rounded-2xl shadow-none relative mx-auto backdrop-blur-lg bg-opacity-40 mt-3 mb-2">
				<div className="z-40 relative h-full w-full">
					<AiOutlineWarning
						onClick={() => {
							setShowActionSheet(true);
							setUserToBlock(uuid);
						}}
						className="text-4xl text-yellow-400 mx-auto border-yellow-400 border rounded-full p-1 my-auto absolute top-3 right-3 bg-white"
					/>
					<img
						onClick={() => {
							setActiveUser(u);
							SetProfileModal(true);
						}}
						className="w-full rounded-2xl h-full border-solid object-cover"
						src={`https://wave-profile.s3.us-west-1.amazonaws.com/${uuid}profile_image${profile_image}`}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = errorImage;
						}}
					/>
				</div>
			</IonCard>
			<div className="text-left w-11/12 mx-auto z-50 backdrop-blur-lg bg-opacity-90 justify-between flex items-center">
				<div className="font-light mx-auto w-full text-gray-900">
					<span className="text-2xl font-wavecondensed font-semibold">
						{name}
					</span>
					<span className="text-xl">, {birthday || ''}</span>
				</div>
			</div>

			{!isLoading ? (
				<div className="flex w-11/12 space-x-2 mx-auto mt-1.5 pt-2 pb-4 rounded-2xl">
					<AiOutlineClose
						className="text-5xl w-1/6 text-white focus:bg-red-200 mx-auto bg-red-500 rounded-full p-3"
						onClick={async () => {
							setIsLoading(true);
							await HandleDislike(ix);
							doRefresh();
						}}
					/>
					<FaHandHoldingHeart
						className="text-white rounded-full text-5xl w-1/2  bg-orange-400 pattern-lines-diagonal-right-gray-700/50 pattern-lines-diagonal-right-scale-[0.5] p-3"
						onClick={async () => {
							setIsLoading(true);
							setIsSaveToast(true);
							await HandleSave(ix);
							doRefresh();
							setIsLoading(false);
						}}></FaHandHoldingHeart>
					<MdWavingHand
						className="text-5xl mx-auto focus:bg-blue-200 w-1/2 pattern-lines-diagonal-right-gray-700/50 pattern-lines-diagonal-right-scale-[0.5] rounded-full p-3 bg-teal-600 text-white"
						onClick={async () => {
							setIsLoading(true);

							await HandleLike(ix);
							doRefresh();
							setIsLoading(false);
						}}
					/>
				</div>
			) : (
				<div className="w-full flex my-4">
					<ImSpinner8 className="mx-auto animate-spin text-wave-blue text-2xl" />
				</div>
			)}
		</div>
	);
};

export default SwipeCard;
