import { useEffect, useState } from 'react';

import { BASE_URL } from '../../globals';
import fetch from 'unfetch';

/**
 *
 * @param {*} externalId true or false if you are checking someone elses
 * @param {*} uuid the uuid of the user to check
 * @returns
 */
const deleteProfile = async () => {
	const url = `${BASE_URL}/api/profile/deleteUser`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			status: 'mid',
		}),
	});

	if (res.ok) {
		return { status: 'success' };
	} else {
		return { status: 'error' };
	}
};
export default deleteProfile;
