import { IonContent, IonModal } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import { BiArrowBack } from 'react-icons/bi';
import { Keyboard } from '@capacitor/keyboard';
import LoadingButton from './buttons/LoadingButton';
import PhotoGrid from '../components/inputs/PhotoGrid';
import deleteFile from '../data/profile/delete';
import getProfileImages from '../data/profile/getProfileImages';
import upload from '../data/profile/upload';

const EditProfileModal = ({ isOpen, SetStatus, User, PresentingElement }) => {
	const modal = useRef();

	const [profileImages, setProfileImages] = useState([
		null,
		null,
		null,
		null,
		null,
		null,
	]);

	const [isLoading, setLoadingStatus] = useState(false);

	useEffect(() => {
		Keyboard.setResizeMode({ mode: 'body' }, (info) => {});
	}, []);

	const Save = async () => {
		setLoadingStatus(true);
		let tempProfileImages = profileImages.filter((item) => item !== null);

		const nullIndices = profileImages
			.map((item, index) => (item === null ? index : undefined))
			.filter((index) => index !== undefined);

		try {
			Promise.all(nullIndices.map((item) => deleteFile(item))).then(
				() => {
					Promise.all(
						tempProfileImages.map((item, index) =>
							upload(item, index)
						)
					).then(() => {
						setLoadingStatus(false);
					});
				}
			);
		} catch (e) {
			setLoadingStatus(false);
		}
	};

	const refreshProfPhotos = async () => {
		const newImages = await getProfileImages();
		if (newImages.status === 'success') {
			let tempProfileArray = newImages?.images;
			while (tempProfileArray.length < 6) {
				tempProfileArray.push(null);
			}
			setProfileImages(tempProfileArray);
		}
		return true;
	};

	useEffect(() => {
		refreshProfPhotos();
	}, []);

	return (
		<IonModal
			ref={modal}
			isOpen={isOpen}
			className="z-50"
			PresentingElement={PresentingElement}>
			<IonContent color={'secondary'} scrollY={false}>
				<BiArrowBack
					className="back-arrow mt-safe"
					onClick={() => {
						SetStatus(false);
					}}
				/>
				<div className="w-full flex flex-col mt-safe items-center h-full bg-white pattern-polka-gray-500/20 pattern-polka-scale-[.25] rounded-t-3xl">
					<div className="font-bold w-full mt-2 text-center mx-auto text-slate-800 font-wavecondensed text-4xl">
						PROFILE
					</div>

					<div className="w-full flex flex-col items-center">
						<div className="w-full mt-12 z-50">
							<PhotoGrid
								profileImages={profileImages}
								setProfileImages={setProfileImages}
								User={User}
							/>
						</div>
						{/* <LoadingButton
							OnClick={Save}
							Message={'Save'}
							AdditionalButtonStyle={'mt-2 px-2'}
							IsLoading={isLoading}
						/> */}
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};

export default EditProfileModal;
