import {
	IonContent,
	IonRefresher,
	IonRefresherContent,
	IonToast,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';

import LikedByCard from '../components/swipe/LikedByCard';
import LikesPage from './LikesPage';
import LoadingPageTwo from '../components/ui/LoadingPageTwo';
import { RemoveUUIDFromArray } from '../components/utils/ArrayFunctions';
import SafetyOptions from '../components/safety/blockActionSheet';
import SwipePopUp from '../components/swipe/NewSwipe';
import blockUser from '../data/safety/block';
import { chevronDownCircleOutline } from 'ionicons/icons';
import getLikedBy from '../data/get/getLikedBy';
import getSaved from '../data/get/getSaved';
import getUsers from '../data/get/getUsers';
import like from '../data/swipe/like';
import save from '../data/swipe/save';
import updateUserLocation from '../data/addLocation';
// import verifyUser from '../data/auth/verifyUser';

const SavesWavesPage = ({
	currPage,
	loadedUsers,
	setLoadedUsers,
	usersToSwipe,
	setUsersToSwipe,
	userProf,

	setShowWarning,
	setShowFooter,
	setLikedBy,
	likedBy,
	matches,
	savedUsers,
	setSavedUsers,
	setMatches,
	userLon,
	userLat,
	setUserLat,
	setUserLon,
}) => {
	const [showActionSheet, setShowActionSheet] = useState(false);
	const [userToBlock, setUserToBlock] = useState(null);

	const [toastGeneral, setToastGeneral] = useState(false);
	const [toastGeneralMsg, setToastGeneralMsg] = useState('');
	const [swipeNum, setSwipeNum] = useState(0);
	const [isProfileModalActive, setProfileModalStatus] = useState(false);
	const [activeUser, setActiveUser] = useState(null);

	const [isMatchToastActive, setMatchToastStatus] = useState(false);
	const [isSaveToast, setIsSaveToast] = useState(false);
	const [showLikedModal, setShowLikedModal] = useState(false);
	const [activeTab, setActiveTab] = useState(0);

	const getUserLoc = async () => {
		let options = {
			maximumAge: 3000,
			enableHighAccuracy: true,
		};
		const resp = {};
		const respLatString = userLat;
		const respLongString = userLon;
		setUserLat(respLatString);
		setUserLon(respLongString);
	};
	useEffect(() => {
		// verifyUser().then((data) => {
		// 	if (data.status === 'error') {
		// 		localStorage.clear();
		// 		window.location = '/welcome';
		// 	}
		getUserLoc().then((data) => {});
		// });
	}, []);

	useEffect(() => {
		if (!loadedUsers) {
			if (userLat && userLon) {
				updateUserLocation(userLat, userLon).then((data) => {
					if (userLat && userLon) {
						getUsers(userLat, userLon).then((data) => {
							if (data.status) {
								setLoadedUsers(true);
								if (data.data[0]) {
									setUsersToSwipe(data.data);
								} else {
								}
							}
						});
					}
				});
			}
		}
	}, [userLat, userLon, currPage, loadedUsers, isProfileModalActive]);

	const pageRef = useRef();
	const updateSwipeArr = async (arr) => {
		await setUsersToSwipe(arr);
	};

	const doRefresh = async (event) => {
		if (userLat && userLon) {
			updateUserLocation(userLat, userLon).then((data) => {
				if (userLat && userLon) {
					getUsers(userLat, userLon).then((data) => {
						if (data.status) {
							setLoadedUsers(true);

							setUsersToSwipe(data.data);
						}
					});
				}
			});
		}
		getLikedBy().then((data) => {
			setLikedBy(data.data);
		});

		getSaved().then((data) => {
			if (data?.data?.status === 'succ') {
				setSavedUsers(data?.data?.data);
			}
		});
		setTimeout(() => {
			event?.detail.complete();
		}, 4000);
	};

	const BlockUser = (userToBlock) => {
		blockUser(userToBlock).then(() => {
			let newUserList = RemoveUUIDFromArray(usersToSwipe, userToBlock);
			setUsersToSwipe(newUserList);
		});
	};

	useEffect(() => {
		if (userProf?.isVisible) {
			setShowWarning(false);
		} else {
			setShowWarning(true);
		}
	}, [userProf]);

	const HandleDislike = async (uuid) => {
		const toDislikeUUID = uuid;

		await like(toDislikeUUID, false);

		setToastGeneralMsg('User disliked!');
		await doRefresh();
		setProfileModalStatus(false);
	};
	const HandleSave = async (uuid) => {
		const toSaveUUID = uuid;

		await save(toSaveUUID);

		const fetchAllUsers = await getUsers(userLat, userLon);

		if (fetchAllUsers.status) {
			setToastGeneralMsg('User saved for 24 hours!');
		}
		await doRefresh();
		setProfileModalStatus(false);
	};

	const HandleLike = async (uuid) => {
		let postLikeData = await like(uuid, true);
		console.log(postLikeData);
		if (postLikeData?.data?.isMatch === true) {
			setToastGeneralMsg('Matched!');
			setMatchToastStatus(true);
		} else {
			setToastGeneralMsg('Wave sent!');
			setToastGeneral(true);
		}
		await doRefresh();
		setProfileModalStatus(false);
	};

	const homeRef = useRef();
	function quintupleArray(arr) {
		let quintupledArr = [];
		for (let i = 0; i < arr.length; i++) {
			for (let j = 0; j < 10; j++) {
				quintupledArr.push(arr[i]);
			}
		}
		return quintupledArr;
	}
	return (
		<>
			<IonContent
				ref={homeRef}
				className="bg-wave-bg flex flex-col justify-center"
				color="secondary"
				scrollY={true}>
				{loadedUsers ? (
					<>
						<IonRefresher
							slot="fixed"
							className="mt-safe z-50"
							onIonRefresh={doRefresh}>
							<IonRefresherContent
								color={'primary'}
								pullingIcon={chevronDownCircleOutline}
								className=""
								refreshingSpinner={
									'crescent'
								}></IonRefresherContent>
						</IonRefresher>
						<div className="h-safe w-full flex flex-col mt-safe pb-safe">
							<div className="w-full flex t-safe font-bold text-slate-800 font-wavecondensed text-4xl">
								<span className="mx-auto">WAVES AND SAVES</span>
							</div>
							<div className="w-full flex items-center mt-6">
								<div
									onClick={() => {
										setActiveTab(0);
									}}
									className={`w-1/2 font-medium border border-teal-600 rounded-t-xl py-2 text-center ${
										activeTab === 0
											? 'bg-teal-600 text-white'
											: 'text-teal-600'
									}`}>
									Waves
								</div>
								<div
									onClick={() => {
										setActiveTab(1);
									}}
									className={`w-1/2 font-medium border border-orange-400 rounded-t-xl py-2 text-center ${
										activeTab === 1
											? 'bg-orange-400 text-white'
											: 'text-orange-400'
									}`}>
									Saves
								</div>
							</div>
							<SwipePopUp
								setShowFooter={setShowFooter}
								doRefresh={doRefresh}
								setMatchToastStatus={setMatchToastStatus}
								activeUser={activeUser}
								isOpen={isProfileModalActive}
								PresentingElement={pageRef.current}
								SetStatus={setProfileModalStatus}
								User={activeUser}
								SetSwipeNum={setSwipeNum}
								SwipeNum={swipeNum}
								showLikeOptions={true}
								modalRef={homeRef}
								BlockUser={BlockUser}
								HandleSave={HandleSave}
								HandleLike={HandleLike}
								HandleDislike={HandleDislike}
							/>

							<LikesPage
								showLikedModal={showLikedModal}
								setShowFooter={setShowFooter}
								setShowLikedModal={setShowLikedModal}
								matches={matches}
								setMatches={setMatches}
								setLikeData={setLikedBy}
								likeData={likedBy}
								doRefresh={doRefresh}
								setMatchToastStatus={setMatchToastStatus}
								isProfileModalActive={isProfileModalActive}
								setProfileModalStatus={setProfileModalStatus}
								activeUser={activeUser}
								setSwipeNum={setSwipeNum}
								swipeNum={swipeNum}
								setActiveUser={setActiveUser}
							/>

							{activeTab === 0 ? (
								likedBy && likedBy.length !== 0 ? (
									<div className="flex w-full mx-auto justify-center flex-wrap pb-[calc(100px)]">
										{likedBy.map((u, ix) => {
											return (
												<LikedByCard
													key={ix + u}
													first_name={u?.first_name}
													profile_images={
														u?.profile_images
													}
													uuid={u?.uuid}
													SetProfileModal={
														setProfileModalStatus
													}
													User={u}
													setActiveUser={
														setActiveUser
													}
												/>
											);
										})}
									</div>
								) : (
									<div className="flex flex-col justify-center mt-20 w-full text-3xl text-center text-slate-600">
										No Waves at the moment
										<div className="text-base">
											Go catch some waves
										</div>
									</div>
								)
							) : null}

							{activeTab === 1 ? (
								savedUsers && savedUsers.length !== 0 ? (
									<div className="flex w-full mx-auto justify-center flex-wrap pb-[calc(100px)]">
										{savedUsers?.map((u, ix) => {
											return (
												<LikedByCard
													key={ix + u}
													first_name={u?.first_name}
													profile_images={
														u?.profile_images
													}
													uuid={u?.uuid}
													SetProfileModal={
														setProfileModalStatus
													}
													User={u}
													setActiveUser={
														setActiveUser
													}
												/>
											);
										})}
									</div>
								) : (
									<div className="flex flex-col justify-center mt-20 w-full text-3xl text-center text-slate-600">
										No Saves at the moment
										<div className="text-base">
											Not sure? Want to wait? That's what
											this is here for
										</div>
									</div>
								)
							) : null}
						</div>

						<IonToast
							isOpen={isMatchToastActive}
							message={'Congratulation! New Match'}
							duration={4000}
							onWillDismiss={() => {
								setMatchToastStatus(false);
							}}
							color="primary"
							position="top"
						/>
						<IonToast
							isOpen={toastGeneral}
							message={toastGeneralMsg}
							duration={4000}
							onWillDismiss={() => {
								setToastGeneral(false);
							}}
							color="primary"
							position="top"
						/>
						<IonToast
							isOpen={isSaveToast}
							message={'User saved for 24 hours!'}
							duration={4000}
							onWillDismiss={() => {
								setIsSaveToast(false);
							}}
							color="primary"
							position="top"
						/>
					</>
				) : (
					<LoadingPageTwo />
				)}
				<SafetyOptions
					setShowActionSheet={setShowActionSheet}
					showActionSheet={showActionSheet}
					userToBlock={userToBlock}
					blockUser={BlockUser}
				/>
			</IonContent>
		</>
	);
};

export default SavesWavesPage;
