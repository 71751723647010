import './ConversationCard.css';

const ConversationCard = ({ User, SetMessaging }) => {
	function convertTime(timeStr) {
		const now = new Date(); // Get the current date and time
		const time = new Date(parseInt(timeStr)); // Parse the input time string into a Date object

		// Calculate the difference between now and the input time in milliseconds
		const diffMillis = now.getTime() - time.getTime();

		// Calculate the difference in days (rounded down)
		const diffDays = Math.floor(diffMillis / (1000 * 60 * 60 * 24));

		// If the difference is less than 1 day, return the time in AM/PM format
		if (diffDays < 1) {
			return time.toLocaleTimeString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			});
		}
		// If the difference is 1 day, return "Yesterday"
		else if (diffDays === 1) {
			return 'Yesterday';
		}
		// If the difference is more than 2 days, return the date in MM/DD/YYYY format
		else {
			const month = time.getMonth() + 1;
			const day = time.getDate();
			const year = time.getFullYear();
			return `${month}/${day}/${year}`;
		}
	}

	return User ? (
		<div
			onClick={() => {
				SetMessaging(User);
			}}
			className="flex border-wave-inactive border-b-2 border-opacity-30 p-2 pr-3 items-center">
			<img
				className="w-16 h-16 rounded-full object-cover border-2 mr-4 shrink-0"
				src={
					User?.profile_images[0] === undefined
						? 'https://p.kindpng.com/picc/s/164-1646900_error-free-svg-alarma-de-sismo-hd-png.png'
						: `https://wave-profile.s3.us-west-1.amazonaws.com/${User?.match_uuid}profile_image${User?.profile_images[0]}`
				}
				alt="Profile"
			/>
			<div className="flex flex-col grow text-gray-900">
				<div className="w-full flex flex-row items-center justify-between">
					<div className="font-bold text-wave-text text-xl">
						{User?.first_name || 'First'}
					</div>
					<div className="conversation-time font-wavecondensed font-semibold text-gray-500">
						{convertTime(User?.message_time)}
					</div>
				</div>

				<div className="conversation-last-message line-clamp-2">
					{User?.most_recent_message || 'Message could not be loaded'}
				</div>
			</div>
		</div>
	) : null;
};

export default ConversationCard;
