import './LoadingPageTwo.css';
import { useEffect, useRef, useState } from 'react';
import WaveLoadingAnimation from '../utils/WaveLoadingAnimation';

const LoadingPageTwo = () => {
	const phrases = [
		'Paddling Out....',
		'Looking For A Wave....',
		'Avoiding tourists....',
		'Hanging Outside The Break....',
	];
	const [pos, setPos] = useState(0);
	const [secondLoad, setSecondLoad] = useState(false);
	const [thirdLoad, setThirdLoad] = useState(false);
	const updatePos = (np) => {
		setPos(np);
	};
	useEffect(() => {
		let timer;
		if (pos !== phrases.length - 1) {
			let np = pos + 1;
			timer = setTimeout(() => {
				updatePos(np);
			}, 3000);
		} else {
			timer = setTimeout(() => {
				updatePos(0);
			}, 3000);
		}
		return () => clearTimeout(timer);
	}, [pos]);
	useEffect(() => {
		setTimeout(() => {
			setSecondLoad(true);
		}, 500);
		setTimeout(() => {
			setThirdLoad(true);
		}, 1000);
	}, []);

	return (
		<div className="w-screen h-full flex flex-col">
			<WaveLoadingAnimation />
			<div className="w-screen h-full flex flex-col">
				<div className=" flex z-50">
					<span className="mx-auto font-light text-gray-900 text-xl italic">
						{phrases[pos]}
					</span>
				</div>
				<div className="mt-12 flex z-50 text-center mx-auto text-sky-900 font-wavecondensed font-bold">
					Searching nearby...
				</div>
			</div>
		</div>
	);
};

export default LoadingPageTwo;
