import LoadingButton from '../../buttons/LoadingButton';
import TelInput from '../../inputs/TelInput';
import TextInput from '../../inputs/TextInput';

const SUPage3 = ({
	wrapperStyle,
	headerStyle,
	AdditionalInputWrapperStyle,
	SetState,
	AdditionalButtonStyle,
	IsLoading,
	OnClick,
	OnInputChange,
	InputValue,
}) => {
	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>My first name is</div>
				<TextInput
					Placeholder={'First Name'}
					SetState={SetState}
					AdditionalWrapperStyle={AdditionalInputWrapperStyle}
					OnInputChange={OnInputChange}
					Value={InputValue}
				/>
			</>
			<LoadingButton
				IsLoading={IsLoading}
				OnClick={OnClick}
				Message="Continue"
				IsSecondary={false}
				AdditionalButtonStyle={AdditionalButtonStyle}
			/>
		</div>
	);
};

export default SUPage3;
