import LoadingButton from '../../buttons/LoadingButton';
import TelInput from '../../inputs/TelInput';

const LoginPage1 = ({
	SetState,
	IsLoading,
	OnClick,
	wrapperStyle,
	headerStyle,
	AdditionalInputWrapperStyle,
	AdditionalButtonStyle,
}) => {
	return (
		<div className={wrapperStyle}>
			<>
				<div className={headerStyle}>Login with your phone number</div>
				<TelInput
					Placeholder={'Phone Number'}
					SetState={SetState}
					AdditionalWrapperStyle={AdditionalInputWrapperStyle}
				/>
			</>
			<LoadingButton
				IsLoading={IsLoading}
				OnClick={async () => {
					OnClick();
				}}
				Message="Login"
				IsSecondary={false}
				AdditionalButtonStyle={AdditionalButtonStyle}
			/>
		</div>
	);
};

export default LoginPage1;
