import {
	IonButtons,
	IonContent,
	IonHeader,
	IonModal,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';

import { AiOutlineClose } from 'react-icons/ai';
import LoginPage1 from './Login/LoginPage1';
import LoginPage2 from './Login/LoginPage2';
import { VerifyPhoneNumber } from '../utils/FormInputVerification';
import confirmLogin from '../../data/login/confirmLogin';
import loginSMS from '../../data/login/loginSMS';
import { useState } from 'react';

const LoginModal = ({ isOpen, onDidDismiss }) => {
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [displayCodeInput, setDisplayCodeInput] = useState(false);
	const [code, setCode] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);

	const HandleLogin = () => {
		setIsLoading(true);
		let pnValid = VerifyPhoneNumber(phoneNumber);
		if (pnValid !== true || !pnValid) {
			setIsLoading(false);
			setErrorMessage(pnValid);

			return;
		}
		loginSMS(phoneNumber).then((resp) => {
			if (resp.status === 'error' || !resp) {
				setIsLoading(false);
				setErrorMessage('Error logging you in. Double check your info');
				return;
			} else {
				setIsLoading(false);
				setDisplayCodeInput(true);
			}
		});
	};

	const SubmitLogin = async () => {
		setIsLoading(true);
		let didLogin = await confirmLogin(code, phoneNumber);

		if (didLogin.status !== 'success') {
			setIsLoading(false);
			setErrorMessage('Error logging you in.');
		} else {
			setErrorMessage('Success');
			window.location = '/home';
		}
	};

	// Duplicated in SignUpModal.js
	let wrapperStyle = 'flex flex-col w-full py-10';
	let headerStyle =
		'text-center text-4xl font-light w-3/4 mx-auto text-slate-900 font-semibold text-opacity-90 mb-10';
	let additionalInputWrapperStyle = 'w-3/4 mt-4 text-gray-200';
	let additionalButtonStyle = 'mt-16 w-3/4';

	return (
		<IonModal
			fullscreen={true}
			isOpen={isOpen}
			onDidDismiss={onDidDismiss}
			color={'secondary'}>
			<IonHeader color={'secondary'} class="ion-no-border" className="">
				<IonToolbar color={'secondary'}>
					<IonButtons slot="end">
						<IonText color="light" onClick={onDidDismiss}>
							<AiOutlineClose className="text-wave-inactive text-2xl mr-2" />
						</IonText>
					</IonButtons>
					{/* <IonTitle>{'Login' || 'Menu'}</IonTitle> */}
				</IonToolbar>
			</IonHeader>
			<IonContent
				color={'secondary'}
				scrollY={false}
				className="h-screen w-full Z-50 flex ">
				<div className="relative w-full bg-wave-bg">
					<IonToast
						isOpen={errorMessage !== null}
						message={errorMessage}
						duration={1000}
						onWillDismiss={() => {
							setErrorMessage(null);
						}}
						color="primary"
						position="top"
					/>
					<div className="modal-wrapper">
						{!displayCodeInput ? (
							<LoginPage1
								SetState={setPhoneNumber}
								IsLoading={isLoading}
								OnClick={HandleLogin}
								wrapperStyle={wrapperStyle}
								headerStyle={headerStyle}
								AdditionalInputWrapperStyle={
									additionalInputWrapperStyle
								}
								AdditionalButtonStyle={additionalButtonStyle}
							/>
						) : (
							<LoginPage2
								SetState={setCode}
								IsLoading={isLoading}
								SubmitLogin={SubmitLogin}
								wrapperStyle={wrapperStyle}
								headerStyle={headerStyle}
								AdditionalButtonStyle={additionalButtonStyle}
							/>
						)}
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};
export default LoginModal;
