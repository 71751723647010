import { BiArrowBack } from 'react-icons/bi';

const ModalBackButton = ({ modal, setStatus }) => {
	return (
		<div className="bg-gray-400 absolute top-20 bg-opacity-80 rounded-lg left-6 text-gray-800 text-xl px-2 py-2">
			<BiArrowBack
				className=""
				onClick={() => {
					modal.current.dismiss().then(() => {
						setStatus(false);
					});
				}}
			/>
		</div>
	);
};
export default ModalBackButton;
