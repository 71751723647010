import { useEffect, useRef } from 'react';

const TextInputArea = ({
	Placeholder,
	SetState,
	AdditionalWrapperStyle,
	AdditionalInputStyle,
	AdditionalLabelStyle,
	Value,
}) => {
	const textInput = useRef();

	useEffect(() => {
		if (Value) {
			textInput.current.value = Value;
		}
	}, []);

	return (
		<div class={`relative mx-auto ${AdditionalWrapperStyle}`}>
			<textarea
				ref={textInput}
				onChangeCapture={() => {
					SetState(textInput.current.value);
				}}
				rows={6}
				maxLength={300}
				type="text"
				inputmode="text"
				id="floating_outlined"
				class={`resize-none block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-wave-secondary appearance-none  focus:outline-none focus:ring-0 focus:border-wave-blue peer ${AdditionalInputStyle}`}
				placeholder=" "
			/>
			<label
				for="floating_outlined"
				class={`absolute text-sm text-wave-secondary duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-wave-bg  px-2 peer-focus:px-2 peer-focus:text-wave-blue  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-6 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${AdditionalLabelStyle}`}>
				{Placeholder || 'Placeholder'}
			</label>
		</div>
	);
};

export default TextInputArea;
