import fetch from 'unfetch';
import { BASE_URL } from '../../globals';
/**
 * 
 * @param {*} FCM_TOKEN 

 * 
 * 
 * @returns 
 */
const updateFCM = async (FCM_TOKEN) => {
	const url = `${BASE_URL}/api/notifications/updateFCM`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			fcm_token: FCM_TOKEN,
		}),
	});

	if (res.ok) {
		return { status: 'success' };
	} else {
		let rd = await res.json();
		return { status: 'error' };
	}
};

export default updateFCM;
