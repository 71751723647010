export function processMessages(messages) {
	if (!messages) return null;
	const timeDiffThreshold = 7200000;

	for (let i = 0; i < messages.length; i++) {
		if (i === 0) {
			messages[0].time_string = formatDateTime(
				new Date(messages[0].created_at)
			);
		} else {
			const prevMsg = messages[i - 1];
			let currMsg = messages[i];

			const timeDiff =
				new Date(currMsg.created_at) - new Date(prevMsg.created_at);

			if (timeDiff > timeDiffThreshold) {
				const dateObj = new Date(currMsg.created_at);
				const time_string = formatDateTime(dateObj);
				currMsg.time_string = time_string;
			}
		}
	}

	return messages;
}

function formatDateTime(date) {
	const monthAbbrev = date.toLocaleString('default', { month: 'short' });
	const day = date.getDate();
	const time = formatTime(date);
	return `${monthAbbrev} ${day}, ${time}`;
}

function formatTime(date) {
	const hours = padZero(date.getHours());
	const minutes = padZero(date.getMinutes());
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const hour12 = hours % 12 || 12;
	return `${hour12}:${minutes} ${ampm}`;
}

function padZero(n) {
	return n < 10 ? '0' + n : n;
}
