import { AiOutlineCheck } from 'react-icons/ai';

const ActiveButton = ({
	AdditionalButtonStyle,
	Value,
	SetState,
	Message,
	IsActive,
}) => {
	return (
		<button
			onClickCapture={() => {
				SetState(Value);
			}}
			className={`border-2 border-solid
		font-semibold rounded-lg h-12 mx-auto cursor-pointer transition-all duration-100 flex items-center justify-between px-4 ${
			IsActive
				? 'border-wave-blue text-wave-blue'
				: 'border-wave-inactive text-wave-inactive'
		} ${AdditionalButtonStyle}`}>
			<>{Message || 'Button'}</>{' '}
			<div
				className={`h-5 w-5 border-2 rounded-full transition-all duration-300 ${
					IsActive
						? 'border-wave-blue bg-wave-blue'
						: 'border-wave-inactive'
				}`}>
				{IsActive ? (
					<AiOutlineCheck className="text-white p-0.5" />
				) : null}
			</div>
		</button>
	);
};

export default ActiveButton;
