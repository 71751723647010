import './input.css';
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { React } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import MainPage from './pages/MainPage.js';
import WelcomePage from './pages/WelcomePage.js';

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

// setupIonicReact({ swipeBackEnabled: false });

const App = () => {
	return (
		<IonApp className="App">
			<IonReactRouter>
				<IonRouterOutlet
					mode={'ios'}
					animated={false}
					draggable={false}>
					<Switch>
						<Route exact path="/welcome" component={WelcomePage} />
						<Route
							exact
							path="/login"
							component={WelcomePage}></Route>
						<Route exact path="/home" component={MainPage} />
						<Route exact path="/">
							<Redirect to="/welcome" />
						</Route>
						<Route path="*">
							<Redirect to="/welcome" />
						</Route>
					</Switch>
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	);
};
export default App;
