/**
 *
 * @param {array} array Array of user objects from which val will be removed
 * @param {uuid} userToBeRemoved UUID of user to be removed
 * @returns Array with selected user removed
 */

export const RemoveUUIDFromArray = (array, userToBeRemoved) => {
	let newArray = array.filter(function (user) {
		return user?.uuid !== userToBeRemoved;
	});

	return newArray;
};

export const DeepCopyArray = (array) => {
	return JSON.parse(JSON.stringify(array));
};
