import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import login from '../login/login';
import reAuthorize from './reAuthorize';
import { BASE_URL } from '../../globals';

const verifyUser = async (type) => {
	const url = `${BASE_URL}/api/verifyuser/`;
	let p = localStorage.getItem('auth');
	const res = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		headers: {
			Accept: 'application/json',
			'content-type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
	});

	if (res.ok) {
		return { status: 'success' };
	} else {
		let errorData = await res.json();

		if (errorData?.code === 1) {
			// let d = await reAuthorize();

			// if (d?.status !== 'error') {
			// 	let r = await verifyUser();
			// 	return r;
			// } else {
			return { status: 'error' };
			// }
		}
		return { status: 'error' };
	}
};
export default verifyUser;
