/* eslint-disable jsx-a11y/alt-text */
import './ProfileModal.css';
import '../swipe/SwipeCard.css';
import 'swiper/css';
import 'swiper/css/pagination';

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { IonContent, IonModal } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';

const MessageProfileModal = ({ isOpen, SetStatus, User }) => {
	const [imageIndex, setImageIndex] = useState(0);

	const modal = useRef();
	const imageArr = User?.profile_images;

	return (
		<IonModal ref={modal} slot="fixed" isOpen={isOpen} canDismiss={true}>
			<IonContent color="secondary" scrollY={false}>
				<div className="w-full h-t-safe mt-safe pb-safe flex flex-col justify-between bg-white pattern-polka-gray-500/20 pattern-polka-scale-[.25] rounded-t-3xl">
					{imageArr === null || imageArr?.length === 0 ? (
						<AiOutlineClose
							onClick={() => {
								modal.current.dismiss().then(() => {
									SetStatus(false);
								});
							}}
							className="absolute z-50 t-safe mt-2 right-2 text-3xl text-gray-500 bg-white border border-gray-500 rounded-full p-2 my-auto"
						/>
					) : null}
					<div className="p-1 pt-1.5 rounded-t-3xl max-h-[calc(80vh)]">
						<Swiper
							modules={[Navigation, Pagination, Scrollbar, A11y]}
							spaceBetween={50}
							slidesPerView={1}
							pagination={{ clickable: true }}
							scrollbar={{ draggable: true }}
							onSwiper={(swiper) => console.log(swiper)}
							onSlideChange={() => console.log('slide change')}
							className="h-1/2">
							{imageArr?.map((key, idx) => {
								return (
									<SwiperSlide key={key}>
										<div className="absolute right-2 top-2 z-[9000000] flex gap-2">
											<AiOutlineClose
												onClick={() => {
													modal.current
														.dismiss()
														.then(() => {
															SetStatus(false);
														});
												}}
												className="text-3xl text-gray-500 bg-white rounded-full p-2 my-auto"
											/>
										</div>

										<img
											className="z-50 h-full w-full object-cover rounded-3xl shadow-black"
											src={
												imageArr[0] !== undefined
													? `https://wave-profile.s3.us-west-1.amazonaws.com/${User?.match_uuid}profile_image${imageArr[idx]}`
													: null
											}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null; // prevents looping
												if (
													imageIndex ===
													imageArr.length
												) {
													setImageIndex(0);
													currentTarget.src =
														imageArr[imageIndex];
												} else {
													setImageIndex(
														imageIndex + 1
													);
													currentTarget.src =
														imageArr[imageIndex];
												}
											}}
										/>
									</SwiperSlide>
								);
							})}
						</Swiper>
						<div className="w-full flex text-left text-lg font-light mt-3 z-20 px-3">
							<span className="font-semibold text-teal-900">
								{User?.first_name},
							</span>
							<span className="w-full ml-1 text-teal-900">
								{User?.birthday}
							</span>
						</div>

						<div className="text-teal-900 text-xs font-light my-2 px-3">
							BIO
						</div>
						<div className="px-3 overflow-y-scroll text-teal-900">
							{User?.bio || ''}
						</div>
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};

export default MessageProfileModal;
