import { useEffect, useState } from 'react';

import fetch from 'unfetch';
import { BASE_URL } from '../../globals';

/**
 *
 * @param {*} userToSave the uuid of the user to save
 
 * @returns status: error or status:success. If success, it will return data with a boolean (didMatch)!
 */
const save = async (userToSave) => {
	const url = `${BASE_URL}/api/swipe/SaveUser/`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: localStorage.getItem('auth'),
		},
		body: JSON.stringify({
			userToSave: userToSave,
		}),
	});

	if (res.ok) {
		let responseData = JSON.parse(JSON.stringify(await res.json()));
		return { status: 'success', data: responseData };
	} else {
		let responseData = JSON.parse(JSON.stringify(await res.json()));

		return { status: 'error' };
	}
};
export default save;
