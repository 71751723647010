import fetch from 'unfetch';
import { BASE_URL } from '../../globals';
import updateInRange from '../location/updateInRange';

const getUsers = async (latitude, longitude) => {
	const url = `${BASE_URL}/api/swipe/get`;
	const res = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: `${localStorage.getItem('auth')}`,
		},
		body: JSON.stringify({
			latitude: latitude,
			longitude: longitude,
		}),
	});

	if (res.ok) {
		let responseData = await res.json();
		if (responseData.length === 0) {
			await updateInRange(false);
		} else {
			await updateInRange(true);
		}
		return { status: true, data: responseData };
	} else {
		return { status: false };
	}
};

export default getUsers;
