import { AiOutlineClose } from 'react-icons/ai';
import LoadingButton from '../../buttons/LoadingButton';
import { useState } from 'react';

const SUPage9 = ({
	wrapperStyle,
	headerStyle,
	AdditionalButtonStyle,
	IsLoading,
	setModalStatus,
	OnClick,
}) => {
	const [isEULAActive, setEULAStatus] = useState(false);

	return (
		<div className={wrapperStyle}>
			{!isEULAActive ? (
				<>
					<>
						<div className={headerStyle}>Terms and Conditions</div>
						<div className="w-2/3 text-black mx-auto text-center text-sm text-gray-500 mb-6">
							Please review the following{' '}
							<span
								onClick={() => {
									setEULAStatus(true);
								}}
								className="underline">
								end user license agreement
							</span>
						</div>
					</>
					<div className="w-2/3 gap-2 mx-auto flex items-center justify-around">
						<LoadingButton
							IsLoading={IsLoading}
							OnClick={() => {
								setModalStatus(false);
							}}
							Message="Decline"
							IsSecondary={false}
							AdditionalButtonStyle={AdditionalButtonStyle}
						/>
						<LoadingButton
							IsLoading={IsLoading}
							OnClick={OnClick}
							Message="Accept"
							IsSecondary={false}
							AdditionalButtonStyle={AdditionalButtonStyle}
						/>
					</div>
				</>
			) : (
				<div className="flex-col h-full items-center justify-center w-full mt-safe">
					<div className="flex flex-col space-y-2 w-3/4 items-center mx-auto h-[calc(60vh)] overflow-y-scroll my-auto text-black">
						<AiOutlineClose
							onClick={() => {
								setEULAStatus(false);
							}}
							className="t-safe right-3 text-2xl text-gray-500"
						/>

						<p>State of Oklahoma Rev. 133EF48</p>
						<p>END USER LICENSE AGREEMENT</p>
						<p>
							This End-User License Agreement (this
							&ldquo;EULA&rdquo;) is a legal agreement between you
							(&ldquo;Licensee&rdquo;) and The Wave Social Inc.
							(&ldquo;Licensor&rdquo;), the author of The Wave,
							including all HTML files, XML files, Java files,
							graphics files, animation files, data files,
							technology, development tools, scripts and programs,
							both in object code and source code (the
							&ldquo;Software&rdquo;), the deliverables provided
							pursuant to this EULA, which may include associated
							media, printed materials, and &ldquo;online&rdquo;
							or electronic documentation.
						</p>
						<p>
							By installing, copying, or otherwise using the
							Software, Licensee agrees to be bound by the terms
							and conditions set forth in this EULA. If Licensee
							does not agree to the terms and conditions set forth
							in this EULA, then Licensee may not download,
							install, or use the Software.
						</p>
						<p>1. Grant of License</p>
						<p>
							A) Scope of License. Subject to the terms of this
							EULA, Licensor hereby grants to Licensee a
							royalty-free, non-exclusive license to possess and
							to use a copy of the Software.
						</p>
						<p>
							B) Installation and Use. Licensee may install and
							use an unlimited number of copies of the Software
							solely for Licensee's personal use.
						</p>
						<p>2. Description of Rights and Limitations</p>
						<p>
							A) Limitations. Licensee and third parties may not
							reverse engineer, decompile, or disassemble the
							Software, except and only to the extent that such
							activity is expressly permitted by applicable law
							notwithstanding the limitation.
						</p>
						<p>
							B) Update and Maintenance. Licensor shall provide
							updates and maintenance on the Software on an as
							needed basis.
						</p>
						<p>
							C) Separation of Components. The Software is
							licensed as a single product. Its components may not
							be separated for use on more than one computer.
						</p>
						<p>
							3. Title to Software. Licensor represents and
							warrants that it has the legal right to enter into
							and perform its obligations under this EULA, and
							that use by the Licensee of the Software, in
							accordance with the terms of this EULA, will not
							infringe upon the intellectual property rights of
							any third parties.
						</p>
						<p>
							4. Intellectual Property. All now known or hereafter
							known tangible and intangible rights, title,
							interest, copyrights and moral rights in and to the
							Software, including but not limited to all images,
							photographs, animations, video, audio, music, text,
							data, computer code, algorithms, and information,
							are owned by Licensor. The Software is protected by
							all applicable copyright laws and international
							treaties.
						</p>
						<p>
							5. No Support. Licensor has no obligation to provide
							support services for the Software.
						</p>
						<p>6. Duration. This EULA is perpetual or until:</p>
						<p>
							A) Automatically terminated or suspended if Licensee
							fails to comply with any of the terms and conditions
							set forth in this EULA; or
						</p>
						<p>
							B) Terminated or suspended by Licensor, with or
							without cause.
						</p>
						<p>
							In the event this EULA is terminated, you must cease
							use of the Software and destroy all copies of the
							Software.
						</p>
						<p>
							7. Jurisdiction. This EULA shall be deemed to have
							been made in, and shall be construed pursuant to the
							laws of the State of Oklahoma, without regard to
							conflicts of laws provisions thereof. Any legal
							action or proceeding relating to this EULA shall be
							brought exclusively in courts located in Norman, OK,
							and each party consents to the jurisdiction thereof.
							The prevailing party in any action to enforce this
							EULA shall be entitled to recover costs and expenses
							including, without limitation, attorneys&rsquo;
							fees. This EULA is made within the exclusive
							jurisdiction of the United States, and its
							jurisdiction shall supersede any other jurisdiction
							of either party&rsquo;s election.
						</p>
						<p>
							8. Non-Transferable. This EULA is not assignable or
							transferable by Licensee, and any attempt to do so
							would be void.
						</p>
						<p>
							9. Severability. No failure to exercise, and no
							delay in exercising, on the part of either party,
							any privilege, any power or any rights hereunder
							will operate as a waiver thereof, nor will any
							single or partial exercise of any right or power
							hereunder preclude further exercise of any other
							right hereunder. If any provision of this EULA shall
							be adjudged by any court of competent jurisdiction
							to be unenforceable or invalid, that provision shall
							be limited or eliminated to the minimum extent
							necessary so that this EULA shall otherwise remain
							in full force and effect and enforceable.
						</p>
						<p>
							10. WARRANTY DISCLAIMER. LICENSOR, AND AUTHOR OF THE
							SOFTWARE, HEREBY EXPRESSLY DISCLAIM ANY WARRANTY FOR
							THE SOFTWARE. THE SOFTWARE AND ANY RELATED
							DOCUMENTATION IS PROVIDED &ldquo;AS IS&rdquo;
							WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
							IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
							WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
							PARTICULAR PURPOSE, OR NON-INFRINGEMENT. LICENSEE
							ACCEPTS ANY AND ALL RISK ARISING OUT OF USE OR
							PERFORMANCE OF THE SOFTWARE.
						</p>
						<p>
							11. LIMITATION OF LIABILITY. LICENSOR SHALL NOT BE
							LIABLE TO LICENSEE, OR ANY OTHER PERSON OR ENTITY
							CLAIMING THROUGH LICENSEE ANY LOSS OF PROFITS,
							INCOME, SAVINGS, OR ANY OTHER CONSEQUENTIAL,
							INCIDENTAL, SPECIAL, PUNITIVE, DIRECT OR INDIRECT
							DAMAGE, WHETHER ARISING IN CONTRACT, TORT, WARRANTY,
							OR OTHERWISE. THESE LIMITATIONS SHALL APPLY
							REGARDLESS OF THE ESSENTIAL PURPOSE OF ANY LIMITED
							REMEDY. UNDER NO CIRCUMSTANCES SHALL
							LICENSOR&rsquo;S AGGREGATE LIABILITY TO LICENSEE, OR
							ANY OTHER PERSON OR ENTITY CLAIMING THROUGH
							LICENSEE, EXCEED THE FINANCIAL AMOUNT ACTUALLY PAID
							BY LICENSEE TO LICENSOR FOR THE SOFTWARE.
						</p>
						<p>
							12. Entire Agreement. This EULA constitutes the
							entire agreement between Licensor and Licensee and
							supersedes all prior understandings of Licensor and
							Licensee, including any prior representation,
							statement, condition, or warranty with respect to
							the subject matter of this EULA.
						</p>
						<p>
							13. Additional Provisions and/or Disclosures. The
							use of objectionable content or abusive behavior
							will not be tolerated on this app. Any breach of
							this conduct will result in immediate removal off of
							the platform
						</p>
					</div>
					<div
						onClick={() => setEULAStatus(false)}
						className="mt-10 w-1/3 text-center text-white rounded-2xl mx-auto bg-slate-800 px-4 py-2">
						Close
					</div>
				</div>
			)}
		</div>
	);
};

export default SUPage9;
